import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    CheckboxIcon,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import { store as localStore } from "../store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { setIsEdited } from "@store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";

import { searchPosInArrayObject } from "@helpers/general";
import MultiCustomSelect from "@components/Common/MultiCustomSelect";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import MultiCustomCreatableSelection from "@components/Common/MultiCustomCreatableSelection";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { languages } from "@constants/index";
import { editPayment } from "./helper";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type PaymentFormType = {
    setIsAddPaymentClicked: Function;
    paymentMethod: React.SetStateAction<any>;
    setIsDataUpdated: Function;
    isDataUpdated: boolean | number;
    data: any;
    setDataIsUpdated: Function;
    setRowData: Function;
    isAddPaymentClicked: boolean;
};

export default function PaymentForm({
    setIsAddPaymentClicked,
    paymentMethod,
    setIsDataUpdated,
    isDataUpdated,
    data,
    setDataIsUpdated,
    setRowData,
    isAddPaymentClicked,
}: PaymentFormType) {
    const { defaultSetting } = useSnapshot(localStore);
    const { t } = useTranslation();
    const { franchiseID, oneShop, shopPays } = useSnapshot(store);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const [choiceMode, setChoiceMode] = React.useState<string>("");
    const [display, setDisplay] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const [inputSaleSupport, setInputSaleSupport] = React.useState<boolean>(
        false
    );
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [inputDisplay, setInputDisplay] = React.useState<boolean>(false);
    const [advancedDisplayName, setAdvancedDisplayName] = React.useState<any>({
        languages: {
            fr: "",
            en: "",
            nl: "",
            es: "",
            pt: "",
            it: "",
        },
    });
    const [countryList, setCountryList] = React.useState<any>([]);
    const [tagsData, setTagsData] = React.useState<any>([]);

    const [selectCountry, setSelectCountry] = React.useState<boolean>(false);
    const [countryData, setCountryData] = React.useState<any>([]);
    const [salesSupport, setSalesSupport] = React.useState<any>([]);
    const [salesSupportData, setSalesSupportData] = React.useState<any>([]);
   
    async function editPaymentMethod() {
        if (
            display ===
                paymentMethod.advancedDisplayName.languages[i18nextLng] &&
            salesSupport.join(",") === paymentMethod["Support de vente"] &&
            [...new Set([...countryData])].join(",") ===
                paymentMethod["Country"].labels.join(",") &&
            tagsData.join(",") === paymentMethod.Tag
        ) {
            setIsModalOpened(true);
        } else {
            toast.dismiss();
            let ids: any = [];
            countryData.forEach((element: any) => {
                ids.push(
                    countryList.find((el: any) => el.label === element).value
                );
            });
            const updatedRows = data.map((row: any) => {
                if (row.Id === paymentMethod.Id) {
                    return {
                        ...row,
                        designation: choiceMode,
                        display: display,
                        "Display name": display,
                        shopId: 0,
                        country: oneShop
                            ? [
                                  ...new Set([...countryData, ...countryList]),
                              ].join(",")
                            : [...new Set([...countryData])].join(","),
                        Country: { ids: ids, labels: countryData },
                        Pays: { ids: ids, labels: countryData },
                        saleSupport: salesSupport.join(","),
                        support_vente: salesSupport,
                        "Support de vente": salesSupport.join(","),
                        Tag: tagsData.join(","),
                        advancedDisplayName: advancedDisplayName,
                        status:
                            display === "" ||
                            salesSupport.length === 0 ||
                            countryData.length === 0
                                ? false
                                : row.status,
                    };
                }
                return { ...row };
            });
            editPayment(
                franchiseID,
                updatedRows,
                setIsAddPaymentClicked,
                setIsDataUpdated,
                isDataUpdated
            );
            toast.success(
                `${t("The modification has been successfully completed")}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        }
    }

    const formatCreateLabel = (inputValue: any) => (
        <span>
            {inputValue} ({t("New tag")})
        </span>
    );
    const formatCreateLabelPaymentMethod = (inputValue: any) => (
        <span>
            {inputValue} ({t("New payment method")})
        </span>
    );
    const translatedPaymentMethod: any = {
        [t("Cash")]: {
            fr: "Espèces",
            en: "Cash",
            nl: "Bargeld",
            es: "Efectivo",
            pt: "Dinheiro",
            it: "Cash",
        },
        [t("Checks")]: {
            fr: "Chèques",
            en: "Checks",
            nl: "Schecks",
            es: "Cheques",
            pt: "Cheques",
            it: "Assegni",
        },
        [t("Billets restaurants")]: {
            fr: "Tickets restaurants",
            en: "Billets restaurants",
            nl: "Restaurantschecks",
            es: "Vales de restaurante",
            pt: "Vales de restaurante",
            it: "Buoni pasto",
        },
        [t("Credit card")]: {
            fr: "Carte bancaire",
            en: "Credit card",
            nl: "Kreditkarte",
            es: "Tarjeta de crédito",
            pt: "Cartão de crédito",
            it: "Carta di credito",
        },
        [t("Online payment")]: {
            fr: "Paiement en ligne",
            en: "Online payment",
            nl: "Online-Zahlung",
            es: "Pago en línea",
            pt: "Pagamento online",
            it: "Pagamento online",
        },
        [t("Flash Restaurant")]: {
            fr: "Resto flash",
            en: "Flash Restaurant",
            nl: "Flash-Restaurant",
            es: "Flash Restaurante",
            pt: "Flash Restaurante",
            it: "Flash Ristorante",
        },
        [t("TPA")]: {
            fr: "TPA",
            en: "TPA",
            nl: "TPA",
            es: "TPA",
            pt: "TPA",
            it: " TPA",
        },
        [t("Gift")]: {
            fr: "Cadeau",
            en: "Gift",
            nl: "Geschenk",
            es: "Regalo",
            pt: "Presente",
            it: "Regalo",
        },
        [t("Vacation check")]: {
            fr: "Chèque vacances",
            en: "Vacation check",
            nl: "Geschenk",
            es: "Regalo",
            pt: "Presente",
            it: "Regalo",
        },
        [t("Sampling")]: {
            fr: "Prélèvement",
            en: "Sampling",
            nl: "Urlaubscheck",
            es: "Cheque de vacaciones",
            pt: "Cheque de férias",
            it: "Assegno vacanza",
        },
        [t("Payment")]: {
            fr: "Paiement",
            en: "Payment",
            nl: "Zahlung",
            es: "Pago",
            pt: "Pagamento",
            it: "Pagamento",
        },
    };
    const selectedLanguage: any = languages.find(
        (el: any) => el.value === i18nextLng
    );
    const dataFetch = async () => {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/pays`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                        "Accept-Language": i18nextLng,
                    },

                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error!");
                        }

                        let listOfCountry: any = [];
                        data.data.forEach((element: any) => {
                            listOfCountry.push({
                                label: element["Designation"],
                                value: element.Id,
                                frDesignation: element.frenchDesignation,
                            });
                        });

                        if (oneShop) {
                            let shopPaysItems =
                                listOfCountry[
                                    searchPosInArrayObject(
                                        listOfCountry,
                                        "label",
                                        shopPays
                                    )
                                ];
                            if (shopPaysItems !== undefined) {
                                setCountryList([shopPaysItems.label]);
                            }
                        } else {
                            setCountryList(listOfCountry);
                        }
                    })
            );
        } catch (e: any) {}
    };

    useEffect(() => {
        clearMultiCustomSelectionData();
        clearData();
        setTitle(t("Modify a payment method"));
        setTagsData(paymentMethod.Tag.split(","));
        setChoiceMode(paymentMethod[t("Type of payment method")]);
        setDisplay(paymentMethod.advancedDisplayName.languages[i18nextLng]);
        setSalesSupport(paymentMethod[t("Sales support")].split(","));
        setCountryData(paymentMethod["Country"].labels);
        setAdvancedDisplayName(paymentMethod.advancedDisplayName);
        setSalesSupportData(
            defaultSetting.saleSupport.data
                .filter((el: any) => el.status === true)
                .map((elt: any) => elt.support_de_vente)
        );
    }, [paymentMethod, t, i18nextLng, defaultSetting.saleSupport]);

    useEffect(() => {
        if (paymentMethod.id === undefined) {
            setDisplay(choiceMode);
            setInputDisplay(false);
        }
        // eslint-disable-next-line
    }, [choiceMode]);

    React.useEffect(() => {
        dataFetch();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (advancedDisplayName.languages[i18nextLng] !== "") {
            setInputDisplay(false);
        }
        // eslint-disable-next-line
    }, [advancedDisplayName, i18nextLng]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5
                        className={`${classnames(
                            "pt-3 mdr_txt_FRtitleFormPaymentMethod"
                        )}`}
                    >
                        {title}
                    </h5>
                    <CrossIcon
                        className="mdr_icn_FRcloseForm"
                        style={{ cursor: "pointer" }}
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsAddPaymentClicked(false);
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5 `}
                    style={{
                        maxHeight: "470px",
                        overflowY: "auto",
                    }}
                >
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Payment method")}
                        </StyledLabel>

                        <StyledSelectInput
                            isClearable
                            className="mdr_inp_FRpaymentMethod not-allowed-input__clz"
                            options={[]}
                            placeholder={`${t("Select")}…`}
                            autoFocus={false}
                            value={
                                choiceMode === ""
                                    ? null
                                    : {
                                          label: t(choiceMode),
                                          value: t(choiceMode),
                                      }
                            }
                            onChange={(e: any) => {
                                e !== null
                                    ? setChoiceMode(e.label)
                                    : setChoiceMode("");
                                if (e === null && display === "") {
                                    setAdvancedDisplayName({
                                        languages: {
                                            fr: "",
                                            en: "",
                                            nl: "",
                                            es: "",
                                            pt: "",
                                            it: "",
                                        },
                                    });
                                } else if (e !== null) {
                                    setAdvancedDisplayName({
                                        ...advancedDisplayName,
                                        languages:
                                            translatedPaymentMethod[e.label],
                                    });
                                }
                            }}
                            onCreateOption={(e: any) => {
                                setChoiceMode(e);
                            }}
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabelPaymentMethod}
                        />
                    </AvGroup>
                    <AvGroup>
                        <div className="d-flex justify-content-between mb-1">
                            <StyledLabel htmlFor="text" className="mt-3">
                                {t("Display name")}
                            </StyledLabel>
                        </div>
                        <StyledTextInput
                            className={classnames("mdr_inp_FRdisplayName", {
                                input__clz:
                                    inputDisplay ||
                                    (advancedDisplayName.languages[
                                        i18nextLng
                                    ].trim() === "" &&
                                        advancedDisplayName.languages[
                                            i18nextLng
                                        ] !== ""),
                            })}
                            autocomplete="off"
                            name="choiceMode"
                            onChange={(e: any) => {
                                setDisplay(e.target.value);
                                setInputDisplay(false);
                                if (
                                    paymentMethod === "" &&
                                    e.target.value === ""
                                ) {
                                    setAdvancedDisplayName({
                                        languages: {
                                            fr: "",
                                            en: "",
                                            nl: "",
                                            es: "",
                                            pt: "",
                                            it: "",
                                        },
                                    });
                                } else {
                                    setAdvancedDisplayName({
                                        ...advancedDisplayName,
                                        languages: {
                                            ...advancedDisplayName.languages,
                                            [selectedLanguage.value]:
                                                e.target.value,
                                        },
                                    });
                                }
                            }}
                            value={
                                advancedDisplayName.languages[i18nextLng] ?? ""
                            }
                            placeholder={t("Write")}
                            type="text"
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Country")}
                        </StyledLabel>
                        <MultiCustomSelect
                            onDelete={() => {}}
                            blocked={oneShop}
                            result={
                                oneShop
                                    ? [
                                          ...new Set([
                                              ...countryData,
                                              ...countryList,
                                          ]),
                                      ]
                                    : [...new Set([...countryData])]
                            }
                            setResult={setCountryData}
                            data={countryList.map((a: any) => {
                                return a.label;
                            })}
                            onChange={(e: any) => {
                                setSelectCountry(false);
                            }}
                            className={classnames("mdr_inp_FRcountry", {
                                invalid__clz: selectCountry,
                                "not-allowed-input-pm__clz": oneShop,
                            })}
                            placeholder={`${t("Select")}…`}
                            value={t("Choose")}
                            name="country"
                            blockedList={
                                countryList
                                // oneShop ? countryList : allAssociatedCountryShop
                            }
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Sale support")}
                        </StyledLabel>

                        <MultiCustomSelect
                            onDelete={() => {}}
                            result={salesSupport}
                            setResult={setSalesSupport}
                            data={salesSupportData}
                            onChange={(e: any) => {
                                setInputSaleSupport(false);
                            }}
                            className={classnames("mdr_inp_FRsalesSupport", {
                                invalid__clz: inputSaleSupport,
                            })}
                            placeholder={`${t("Select")}…`}
                            value={t("Choose")}
                            name="saleSupport"
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Tag")}
                        </StyledLabel>

                        <MultiCustomCreatableSelection
                            onDelete={() => {}}
                            className="mdr_inp_FRtags"
                            isSendingTag={true}
                            setResult={setTagsData}
                            result={tagsData}
                            data={[]}
                            onChange={(e: any) => {}}
                            onCreateOption={(e: any) => {}}
                            placeholder={`${t("Select")}…`}
                            value={t("Choose")}
                            name="tag"
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabel}
                            isDefault={true}
                        />
                        <div className="d-flex flex-wrap"></div>
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 mdr_btn_FRcancel cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            setIsAddPaymentClicked(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className="mdr_btn_FRvalidate cmn_btn_validate"
                        onClick={() => {
                            editPaymentMethod();
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddPaymentClicked}
                setIsAddClicked={setIsAddPaymentClicked}
                setEditedData={setRowData}
                setIsEdited={setIsEdited}
            />
        </React.Fragment>
    );
}
