import React, { Fragment, useEffect, useState } from "react";
import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { mutate } from "swr";

import { setDefaultSetting, store } from "../store";
import { store as globalStore } from "@components/VerticalLayout/store";

import { CustomIcons } from "./CustomIcons";
import VateRateForm from "./VateRateForm";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";

export function Tva() {
    const { t } = useTranslation();
    const { franchiseID } = useSnapshot(globalStore);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const [filterActive, setLocalFilterActive] = useState<boolean>(false);
    const [isAddVatRateClicked, setIsAddVatRateClicked] = useState<boolean>(
        false
    );
    const [dataIsUpdated, setDataIsUpdated] = useState<boolean | number>(false);
    const [data, setData] = useState<any>({});
    const [rowData, setRowData] = useState<any>({});
    const [url, setUrl] = useState<any>("/DefaultSetting/tva.json");
    const [pays, setPays] = useState<any>([]);
    const { defaultSetting } = useSnapshot(store);

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("VAT code"),
            customJsx: (e: any) => (
                <Fragment>{e.selectedRow["Code TVA"]}</Fragment>
            ),
        },
        {
            indexOFColumn: 4,
            columnName: t("VAT rate"),
            customJsx: (e: any) => (
                <Fragment>{e.selectedRow["Taux TVA"]} %</Fragment>
            ),
        },
        {
            indexOFColumn: 997,
            columnName: t("Country"),
            customJsx: (e: any) => <ListOfPays e={e} />,
        },
        {
            indexOFColumn: 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setIsAddVatRateClicked={setIsAddVatRateClicked}
                    setRowData={setRowData}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                />
            ),
            disableFilter: false,
        },
    ];

    const ListOfPays = (e: any) => {
        let listPays: any = [];
        listPays = e.e.selectedRow[t("Country")]?.labels;

        return (
            <CustomColumnDisplay
                text={`${
                    Array.isArray(listPays) ? listPays.join(",") : " -- "
                }`}
            />
        );
    };
    async function refreshPays() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/pays`;

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                        "Accept-Language": i18nextLng,
                    },

                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error!");
                        }
                        let listOfTag: any = [];

                        data.data.forEach((element: any) => {
                            listOfTag.push({
                                label: element["Designation"],
                                value: element.Id,
                                frDesignation: element.frenchDesignation,
                            });
                        });
                        setPays(listOfTag);
                    })
            );
        } catch (e: any) {}
    }
    useEffect(() => {
        setDefaultSetting({
            ...defaultSetting,
            tva: JSON.parse(JSON.stringify({ data: data.data })),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    React.useEffect(() => {
        refreshPays();
        setUrl(
            `${process.env.REACT_APP_API_V2_URL}/settings/defaultSetting/getData?type=tva&franchiseId=${franchiseID}`
        );
        // eslint-disable-next-line
    }, []);
    return (
        <div>
            <h2 className="mt-3 ml-3">{t("VAT")}</h2>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: isAddVatRateClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    canMovedCheckboxLeftOnExpand
                    url={url}
                    setData={setData}
                    canSelect
                    customSelect
                    canResize
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight={"450px"}
                    maxHeight={"450px"}
                    name="tva"
                />
                {isAddVatRateClicked ? (
                    <VateRateForm
                        dataVatRate={rowData}
                        setEditedData={setRowData}
                        setIsAddVatRateClicked={setIsAddVatRateClicked}
                        pays={pays}
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        data={data.data}
                        isAddVatRateClicked={isAddVatRateClicked}
                        setRowData={setRowData}
                    />
                ) : null}
            </div>
        </div>
    );
}
