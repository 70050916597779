import React from "react";
import { useTranslation } from "react-i18next";
import { ModalHeader } from "reactstrap";

export function ModalHeaderComponent({
    setIsOpen,
    isOpen,
    setNumberClick,
}: any) {
    const { t } = useTranslation();

    return (
        <ModalHeader
            toggle={() => {
                setIsOpen(!isOpen);
                setNumberClick(1);
            }}
            className="text-uppercase"
        >
            {t("Default Configuration Validation")}
        </ModalHeader>
    );
}
