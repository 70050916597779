export const defaultData = {
    Title: {
        FR: "Bienvenue sur notre site Web",
        EN: "Welcome to our website",
        ES: "Bienvenido a nuestro sitio web",
        IT: "Benvenuti nel nostro sito web",
        AL: "Willkommen auf unserer Website", // If AL is for Albanian, change it to: "Mirësevini në faqen tonë të internetit"
    },
    Description: {
        FR:
            "Découvrez et explorez nos boutiques pour une expérience de shopping unique!",
        EN: "Discover and explore our stores for a unique shopping experience!",
        ES:
            "¡Descubre y explora nuestras tiendas para una experiencia de compra única!",
        IT:
            "Scopri ed esplora i nostri negozi per un'esperienza di shopping unica!",
        AL:
            "Entdecken und erkunden Sie unsere Geschäfte für ein einzigartiges Einkaufserlebnis!", // Albanian: "Zbuloni dhe eksploroni dyqanet tona për një përvojë unike të blerjeve!"
    },
} as Record<string, Record<LanguageKey, string>>;

export const languages: Language[] = [
    { key: "FR", label: "Français" },
    { key: "EN", label: "Anglais" },
    { key: "ES", label: "Espagnol" },
    { key: "IT", label: "Italien" },
    { key: "AL", label: "Allemand" }, // If AL is Albanian, change label to "Albanais"
];

export interface Language {
    key: LanguageKey;
    label: string;
}

export type LanguageKey = "FR" | "EN" | "ES" | "IT" | "AL";
