import React from "react";
import { Tooltip } from "@mui/material";
import ReactSwitch from "react-switch";
import { CheckboxIcon, PencilIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";

import { store } from "@components/VerticalLayout/store";

import { editCurrency } from "./helper";

type CustomIconsType = {
    e: any;
    setIsAddCurrencyClicked: Function;
    setRowData: Function;
    setDataIsUpdated: Function;
    dataIsUpdated: boolean | number;
    setIsAddDeviceClicked: Function;
};
export function CustomIcons({
    e,
    setIsAddCurrencyClicked,
    setRowData,
    setDataIsUpdated,
    dataIsUpdated,
    setIsAddDeviceClicked,
}: CustomIconsType): JSX.Element {
    const { t } = useTranslation();
    const { franchiseID } = useSnapshot(store);

    const handlePencilClick = () => {
        setRowData(e.selectedRow);
        setIsAddCurrencyClicked(true);
    };

    const handleSwitchChange = (checked: boolean) => {
        const updatedRows = [{ ...e.selectedRow, status: checked }];
        let result: any = editCurrency(
            franchiseID,
            updatedRows,
            setDataIsUpdated,
            dataIsUpdated,
            setIsAddDeviceClicked
        );
        if (result === true)
            toast.success(
                `${t(
                    checked
                        ? "Currency has been successfully activated"
                        : "Currency has been successfully deactivated"
                )}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
    };

    return (
        <div className="d-flex justify-content-around">
            <Tooltip
                title={
                    e.selectedRow["Désignation décimale"] === "" ||
                    e.selectedRow["Nbr décimale"] === "" ||
                    e.selectedRow["Nom d'affichage"] === "" ||
                    e.selectedRow.Pays.length === 0
                        ? ""
                        : e.selectedRow.status === false
                        ? t("Activate")
                        : t("Unactivate")
                }
                arrow
            >
                <div>
                    <ReactSwitch
                        disabled={
                            e.selectedRow["Désignation décimale"] === "" ||
                            e.selectedRow["Nbr décimale"] === "" ||
                            e.selectedRow["Nom d'affichage"] === "" ||
                            e.selectedRow.Pays.length === 0
                        }
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                        checked={e.selectedRow.status}
                        onChange={() =>
                            handleSwitchChange(!e.selectedRow.status)
                        }
                    />
                </div>
            </Tooltip>

            <div className="ml-3">
                <Tooltip title={t("To modify")} arrow>
                    <div>
                        <PencilIcon
                            onClick={handlePencilClick}
                            height={25}
                            width={25}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}
