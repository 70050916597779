import React from "react";
import { Card, Input } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { CheckIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { store as colorStore } from "@store";

type PropsType = {
    index: number;
    items: any;
    setItems: Function;
    disabled: boolean;
};

export function CardWrapperSpecific({
    index,
    items,
    setItems,
    disabled = false, // Default to false
}: PropsType): JSX.Element {
    const { t } = useTranslation();

    function handleCheckIconOnClickEvent(index: number) {
        let newItem = [...items];
        newItem[index] = { ...newItem[index], active: !newItem[index].active };
        setItems(newItem);
    }
    const { mainColor } = useSnapshot(colorStore);

    return (
        <div
            className={classnames(
                "d-flex flex-column justify-content-center cursor__clz",
                {
                    cac_icn_application:
                        items[index].name === "Application C&C" ||
                        items[index].name === "C&C app",
                    cac_icn_website:
                        items[index].name === "Site web C&C" ||
                        items[index].name === "C&C website",

                    cac_icn_bankCard: items[index].name === t("Credit card"),
                    cac_icn_atTheCounter:
                        items[index].name === t("At the counter"),
                    cac_icn_onTheSpot: items[index].name === t("On the spot"),
                    cac_icn_takeAway: items[index].name === t("To take"),
                    cac_icn_delivery: items[index].name === t("Delivery"),
                }
            )}
            style={{
                gap: "10px",
                borderBottom: "1px solid #707070",
                opacity: items[index].active === false ? "40%" : "",
            }}
        >
            <Card
                className={classnames({
                    "disable-account-creation-items__clz":
                        items[index].name === "Retrait C&C",
                })}
                style={{
                    borderBottom: items[index].active
                        ? `${mainColor} 10px solid`
                        : "unset",
                    width: "250px",
                    height: "220px",
                    borderRadius: "20px",
                    pointerEvents: disabled ? "none" : "auto", // Disable pointer events
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateRows: "0.5fr auto",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "0.2fr 0.6fr 0.2fr",
                            height: "100%",
                        }}
                    >
                        <CheckIcon
                            className="mt-1 ml-1 cmn_chk_selectButton"
                            height={45}
                            width={45}
                            style={{
                                fill: items[index].active
                                    ? mainColor
                                    : "#BCBCBC",
                                cursor: disabled ? "not-allowed" : "pointer", // Change cursor if disabled
                            }}
                            onClick={() => handleCheckIconOnClickEvent(index)}
                        />
                    </div>
                    <div
                        className="d-flex justify-content-center"
                        onClick={() => handleCheckIconOnClickEvent(index)}
                        style={{
                            cursor: disabled ? "not-allowed" : "pointer", // Change cursor if disabled
                        }}
                    >
                        <img
                            alt="img"
                            src={items[index].image}
                            style={{
                                width: "120px",
                                height: "120px",
                            }}
                        />
                    </div>
                </div>
            </Card>
            <AvForm
                className="d-flex  flex-column justify-content-center align-items-center"
                style={{
                    gap: "10px",

                    borderBottom: "1px solid #707070",

                    opacity: items[index].active === false ? "40%" : "",
                }}
            >
                <Input
                    className="border-0"
                    id="card-title"
                    name="card-title"
                    type="text"
                    disabled={true}
                    value={items[index].name}
                    style={{
                        backgroundColor: "transparent",

                        fontSize: "20px",

                        color: "#000000",
                    }}
                />
            </AvForm>
        </div>
    );
}
