import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledSelectInput,
    CheckboxIcon,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";

import {
    setCountryKeyValue,
    store as generalSettingStore,
    setIsEdited,
} from "@pages/SettingGeneral/Language/store";
import { store } from "@components/VerticalLayout/store";
import { store as localStore } from "../store";

import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import { languages } from "@constants/index";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { ImageUploader } from "@pages/SettingGeneral/Language/Languages/ImageUploader";
import { fetchCountry } from "./helper";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";

import "@assets/swal.css";
import "@assets/theme.css";
import "../index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-languages-select/css/react-languages-select.css";

type LanguageFormType = {
    setIsAddLanguageClicked: Function;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    rowData: React.SetStateAction<any>;
    dataIsUpdated: boolean | number;
    setRowData: Function;
    isAddLanguageClicked: boolean;
};

export function LanguageForm({
    setIsAddLanguageClicked,
    setDataIsUpdated,
    rowData,
    dataIsUpdated,
    setRowData,
    isAddLanguageClicked,
}: LanguageFormType) {
    const { t } = useTranslation();
    const { franchiseID } = useSnapshot(store);
    const { countryKeyValue } = useSnapshot(generalSettingStore);
    const { defaultSetting } = useSnapshot(localStore);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [isModalOpened, setIsModalOpened] = React.useState(false);
    const languagesOptions: {
        label: string;
        value: string;
    }[] = languages.map((element: any) => {
        return {
            label: t(element.label),
            value: element.value,
        };
    });
    const selectedLanguage: any = languages.find(
        (el: any) => el.value === i18nextLng
    );
    const [uploadedImage, setUploadedImage] = React.useState<any>("");
    const [langue, setLangue] = React.useState<string>("");
    const [displayName, setDisplayName] = React.useState<string>("");
    const [abbreviation, setAbbreviation] = React.useState<string>("");
    const [selectName, setSelectName] = React.useState<boolean>(false);
    const [country, setCountry] = React.useState<any>("");
    const [selectCountry, setSelectCountry] = React.useState<boolean>(false);
    const [countryOptions, setCountryOptions] = React.useState<any[]>([]);
    const [isNotImg, setIsNotImg] = React.useState<boolean>(false);
    const [selectImage, setSelectImage] = React.useState<boolean>(false);
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const [countryKey, setCountryKey] = React.useState<string>("");
    const [borderColor, setBorderColor] = React.useState("none");
    const [advancedDisplayName, setAdvancedDisplayName] = React.useState<any>({
        languages: {
            fr: "",
            en: "",
            nl: "",
            es: "",
            pt: "",
            it: "",
        },
    });

    const changeHandler = (value: any) => {
        setCountry(value !== null ? value : "");
        setUploadedImage("");
        setCountryKeyValue(
            value !== null && value.code !== undefined ? value.code : ""
        );
        setCountryKey(
            value !== null && value.code !== undefined ? value.code : ""
        );
        setIsEdited(true);
        setSelectCountry(false);
        setSelectImage(false);
        setIsNotImg(false);
    };

    const handleEdit = async () => {
        if (
            displayName === rowData.display_name &&
            country.label === rowData.country.label &&
            rowData.url_image ===
                (uploadedImage !== ""
                    ? uploadedImage
                    : `https://flagpedia.net/data/flags/h80/${countryKeyValue.toLowerCase()}.webp`)
        ) {
            setIsValidateButtonDisabled(false);
            setIsModalOpened(true);
        } else {
            toast.dismiss();
            let row = defaultSetting.language?.data.find(
                (el: any) => el.Id === rowData.Id
            );

            let dataLanguage = {
                ...row,
                display_name: displayName,
                country: country,
                Pays: country.label,
                abbreviation: abbreviation,
                urlImage:
                    uploadedImage !== ""
                        ? uploadedImage
                        : `https://flagpedia.net/data/flags/h80/${countryKeyValue.toLowerCase()}.webp`,
                url_image:
                    uploadedImage !== ""
                        ? uploadedImage
                        : `https://flagpedia.net/data/flags/h80/${countryKeyValue.toLowerCase()}.webp`,
                advancedDisplayName: {
                    ...row.advancedDisplayName,
                    languages: {
                        ...row.advancedDisplayName.languages,
                        [i18nextLng]: displayName,
                    },
                },
                status:
                    displayName === "" || country.label === undefined
                        ? false
                        : row.status,
            };

            const data = new FormData();
            data.append("dataLanguage", JSON.stringify(dataLanguage));
            data.append("image", uploadedImage || ""); // Ensure an image is always sent

            const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/defaultSetting/edit/language?franchiseId=${franchiseID}`;

            const requestOptions = {
                method: "POST",
                body: data,
            };

            try {
                const response = await fetch(apiUrl, requestOptions);
                const result = await response.json();

                if (!response.ok) {
                    throw new Error(result.error || "Error occurred");
                }

                setIsValidateButtonDisabled(false);
                setDataIsUpdated(!dataIsUpdated);
                setIsAddLanguageClicked(false);
                toast.success(
                    `${t("The modification has been successfully completed")}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            } catch (error) {
                console.error("Edit language error:", error);
                toast.error("An error occurred while updating the language.");
            }
        }
    };

    useEffect(() => {
        setSelectCountry(false);
        setSelectName(false);
        setIsNotImg(false);
        setSelectImage(false);
        setLangue(t(rowData.language));
        setAbbreviation(rowData.abbreviation);
        setDisplayName(rowData.display_name);
        setCountry({ ...rowData.country, label: t(rowData.country.label) });
        setUploadedImage(
            rowData.url_image.startsWith("https://flagpedia.net/data/flags/h80")
                ? ""
                : rowData.url_image
        );
        setCountryKeyValue(rowData.country.code ?? "");
        setCountryKey(rowData.country.code ?? "");
        setAdvancedDisplayName(rowData.advancedDisplayName);
        fetchCountry(i18nextLng, setCountryOptions, t);
        // eslint-disable-next-line
    }, [rowData, t]);

    React.useEffect(() => {
        setBorderColor(selectImage || isNotImg ? "red" : "#c3bfbe");
        if (isNotImg) setSelectImage(false);
    }, [isNotImg, selectImage]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5 className="pt-3 lan_txt_FRtitleFormLanguage">
                        {t("Modify a language")}
                    </h5>
                    <CrossIcon
                        className="lan_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsAddLanguageClicked(false);
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5 `}
                    style={{
                        maxHeight: "470px",
                        overflowY: " auto",
                    }}
                >
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Language")}
                        </StyledLabel>
                        <StyledSelectInput
                            isClearable
                            value={
                                langue === ""
                                    ? null
                                    : {
                                          label: langue,
                                          value: langue,
                                      }
                            }
                            className={classnames("lan_inp_FRlanguage", {
                                "not-allowed-input__clz":
                                    rowData.Id !== undefined,
                            })}
                            onChange={() => {}}
                            placeholder={t("Select")}
                            options={languagesOptions}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: `${t(
                                        "Please select a language"
                                    )!}`,
                                },
                            }}
                            name="mark"
                            noOptionsMessage={() => t("No options")}
                            autoFocus={rowData.Id === undefined}
                        />
                    </AvGroup>
                    <AvGroup>
                        <div className="d-flex justify-content-between mb-1">
                            <StyledLabel htmlFor="text" className="mt-3">
                                {t("Display name")}
                            </StyledLabel>
                        </div>
                        <StyledTextInput
                            className={classnames(`lan_inp_FRdisplayName`, {
                                input__clz: selectName,
                            })}
                            autocomplete="off"
                            name="designation"
                            onChange={(e: any) => {
                                setDisplayName(e.target.value);
                                setIsEdited(true);
                                setSelectName(false);
                                if (langue === "" && e.target.value === "") {
                                    setAdvancedDisplayName({
                                        languages: {
                                            fr: "",
                                            en: "",
                                            nl: "",
                                            es: "",
                                            pt: "",
                                            it: "",
                                        },
                                    });
                                } else {
                                    setAdvancedDisplayName({
                                        ...advancedDisplayName,
                                        languages: {
                                            ...advancedDisplayName.languages,
                                            [selectedLanguage.value]:
                                                e.target.value,
                                        },
                                    });
                                }
                            }}
                            value={
                                advancedDisplayName.languages[i18nextLng] ?? ""
                            }
                            placeholder={t("Write")}
                            type="text"
                        />
                        <div className="d-flex flex-wrap "></div>
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Country")}
                        </StyledLabel>
                        <StyledSelectInput
                            isClearable
                            value={country === "" ? null : country}
                            className={classnames("lan_inp_FRcountry", {
                                invalid__clz: selectCountry,
                            })}
                            placeholder={t("Select")}
                            onChange={(e: any) => {
                                changeHandler(e);
                            }}
                            options={countryOptions}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: `${t(
                                        "Please select a country"
                                    )!}`,
                                },
                            }}
                            name="country"
                            noOptionsMessage={() => t("No options")}
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Flag")}
                        </StyledLabel>
                        <span style={{ fontSize: "15px", marginLeft: "3px" }}>
                            ({t("Insert an image such as PNG, JPG or JPEG")})
                        </span>
                        <ImageUploader
                            setIsNotImg={setIsNotImg}
                            uploadedImage={uploadedImage}
                            setUploadedImage={setUploadedImage}
                            setIsEdited={setIsEdited}
                            setSelectImage={setSelectImage}
                            borderColor={borderColor}
                            countryKey={countryKey}
                        />
                        {isNotImg && (
                            <div
                                style={{
                                    width: "100%",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please insert a PNG, JPG or JPEG image.")}
                            </div>
                        )}
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="lan_btn_FRcancel mr-2 cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            setIsAddLanguageClicked(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        disabled={isValidateButtonDisabled}
                        onClick={() => {
                            setIsValidateButtonDisabled(true);
                            handleEdit();
                        }}
                        className={`lan_btn_FRvalidate cmn_btn_validate `}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddLanguageClicked}
                setIsAddClicked={setIsAddLanguageClicked}
                setEditedData={setRowData}
                setIsEdited={setIsEdited}
            />
        </React.Fragment>
    );
}
