import React from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import { DuplicateIcon } from "@aureskonnect/react-ui";

export function AppUid({ e }: any) {
    const { t } = useTranslation();
    const COPY_MESSAGE = "copy UID";
    const COPIED_MESSAGE = "copied to clip board";

    const [tooltipMessage, setTooltipMessage] = React.useState(COPY_MESSAGE);

    async function copyToClipBoard(uid: string) {
        await navigator.clipboard.writeText(uid);
        setTooltipMessage(COPIED_MESSAGE);
    }

    return (
        <React.Fragment>
            {e.selectedRow[t("Application")] ? (
                <div className="d-flex overflow-hidden">
                    <div className="text-truncate">{e.selectedRow.appId}</div>
                    <Tooltip
                        title={t(tooltipMessage)}
                        onClose={() =>
                            setTimeout(() => {
                                setTooltipMessage(COPY_MESSAGE);
                            }, 200)
                        }
                    >
                        <div>
                            <DuplicateIcon
                                width={20}
                                height={20}
                                role="button"
                                onClick={() => {
                                    copyToClipBoard(e.selectedRow.appId);
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
