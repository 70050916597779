import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useSnapshot } from "valtio";

import { setIsAddCity, setIsAddZone } from "../store";
import {
    store as device,
    setActions,
    setIsActionsClicked,
    setIsEdited,
    setActiveSubTab,
} from "../GeneralConfigC&C/City/store";
import { store as colorStore } from "@store";

import Activation from "../GeneralConfigC&C/Activation";
import LoyaltyAccount from "../GeneralConfigC&C/LoyaltyAccount";
import PaymentChoice from "../GeneralConfigC&C/PaymentChoice";
import SalesMethods from "../GeneralConfigC&C/SalesMethods";
import Options from "../GeneralConfigC&C/Options";
import Orders from "../GeneralConfigC&C/Orders";
import Delivery from "../GeneralConfigC&C/Delivery";
import Schedule from "../GeneralConfigC&C/Schedule";
import { CoverPage } from "../Website/CoverPage";
import { ShopPage } from "../Website/ShopPage";
import { templateStore, templateActions } from "../Website/templateStore";

import "./index.css";

interface SubTab {
    title: string;
    content: React.ReactNode;
    customClassName: string;
}

interface Tab {
    title: string;
    content: React.ReactNode;
    customClassName: string;
    subMenu?: SubTab[];
}

interface ModalContentWrapperProps {
    selectedRows: any;
}

export function ModalContentWrapper({
    selectedRows,
}: ModalContentWrapperProps): JSX.Element {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(colorStore);
    const { isActionsClicked, isEdited, activeSubTab } = useSnapshot(device);
    const { template, isLoading } = useSnapshot(templateStore);
    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [openSubmenuTab, setOpenSubmenuTab] = React.useState<number | null>(
        0
    );

    console.info("template modal", template);
    const tabs: Tab[] =
        selectedRows[0].subRows !== undefined
            ? [
                  //   {
                  //       title: t("General settings"),
                  //       content: "",
                  //       customClassName: "",
                  //       subMenu: [
                  //           {
                  //               title: t("Activation"),
                  //               content: <Activation selectedRows={selectedRows} />,
                  //               customClassName: "cac_sid_activation",
                  //           },
                  //           {
                  //               title: t("Loyalty account"),
                  //               content: (
                  //                   <LoyaltyAccount selectedRows={selectedRows} />
                  //               ),
                  //               customClassName: "cac_sid_loyaltyAccount",
                  //           },
                  //           {
                  //               title: t("Payment choice"),
                  //               content: (
                  //                   <PaymentChoice selectedRows={selectedRows} />
                  //               ),
                  //               customClassName: "cac_sid_paymentChoice",
                  //           },
                  //           {
                  //               title: t("Sale Mode"),
                  //               content: <SalesMethods selectedRows={selectedRows} />,
                  //               customClassName: "cac_sid_modeOfSale",
                  //           },
                  //           {
                  //               title: t("Options"),
                  //               content: <Options selectedRows={selectedRows} />,
                  //               customClassName: "cac_sid_options",
                  //           },
                  //           {
                  //               title: t("Orders"),
                  //               content: <Orders selectedRows={selectedRows} />,
                  //               customClassName: "cac_sid_orders",
                  //           },
                  //           {
                  //               title: t("Delivery"),
                  //               content: <Delivery selectedRows={selectedRows} />,
                  //               customClassName: "cac_sid_delivery",
                  //           },
                  //           {
                  //               title: t("Schedule"),
                  //               content: <Schedule selectedRows={selectedRows} />,
                  //               customClassName: "cac_sid_hourly",
                  //           },
                  //       ],
                  //   },
                  {
                      title: t("Website"),
                      content: "",
                      customClassName: "",
                      subMenu: [
                          {
                              title: t("Cover page"),
                              content: (
                                  <CoverPage
                                      selectedRows={selectedRows}
                                      template={template}
                                  />
                              ),
                              customClassName: "cac_sid_activation",
                          },
                          {
                              title: t("Shop page"),
                              content: isLoading ? (
                                  <div>Loading...</div> // Loading state
                              ) : template ? (
                                  <ShopPage />
                              ) : (
                                  <div>No template data available</div> // Error state
                              ),
                              customClassName: "cac_sid_loyaltyAccount",
                          },
                      ],
                  },
              ]
            : [
                  {
                      title: t("Activation"),
                      content: <Activation selectedRows={selectedRows} />,
                      customClassName: "cac_sid_activation",
                  },

                  {
                      title: t("Loyalty account"),
                      content: <LoyaltyAccount selectedRows={selectedRows} />,
                      customClassName: "cac_sid_loyaltyAccount",
                  },
                  {
                      title: t("Payment choice"),
                      content: <PaymentChoice selectedRows={selectedRows} />,
                      customClassName: "cac_sid_paymentChoice",
                  },
                  {
                      title: t("Sale Mode"),
                      content: <SalesMethods selectedRows={selectedRows} />,
                      customClassName: "cac_sid_modeOfSale",
                  },
                  {
                      title: t("Options"),
                      content: <Options selectedRows={selectedRows} />,
                      customClassName: "cac_sid_options",
                  },
                  {
                      title: t("Orders"),
                      content: <Orders selectedRows={selectedRows} />,
                      customClassName: "cac_sid_orders",
                  },
                  {
                      title: t("Delivery"),
                      content: <Delivery selectedRows={selectedRows} />,
                      customClassName: "cac_sid_delivery",
                  },
                  {
                      title: t("Schedule"),
                      content: <Schedule selectedRows={selectedRows} />,
                      customClassName: "cac_sid_hourly",
                  },
              ];

    function handleTabClick(index: number) {
        // Handle edit state if needed
        if (isEdited) {
            setIsActionsClicked(!isActionsClicked);
            setActions(() => {
                setIsEdited(false);
                setIsAddCity(false);
                setIsAddZone(false);
                performTabChange(index);
            });
        } else {
            performTabChange(index);
            setIsAddCity(false);
            setIsAddZone(false);
        }
    }

    const performTabChange = (index: number) => {
        const clickedTabHasSubmenu = tabs[index].subMenu;

        // If clicking a different tab, close current submenu and open new one if applicable
        if (activeTab !== index) {
            setActiveTab(index);
            if (clickedTabHasSubmenu) {
                setOpenSubmenuTab(index);
                setActiveSubTab(0); // Select first submenu item
            } else {
                setOpenSubmenuTab(null);
            }
        }
        // If clicking same tab with submenu, keep it open
        else if (clickedTabHasSubmenu) {
            setOpenSubmenuTab(index);
        }
    };

    const handleSubmenuClick = React.useCallback((subMenuIndex: number) => {
        setActiveSubTab(subMenuIndex);
    }, []);

    React.useEffect(() => {
        templateActions.setLoading(true); // Set loading when starting fetch
        fetch(
            `http://localhost:8585/api_C&C/v1/getApkTemplate/${selectedRows[0].iuudApk}`
        )
            .then((res) => res.json())
            .then((result) => {
                templateActions.setTemplate(result.template);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            })
            .finally(() => {
                templateActions.setLoading(false); // Set loading false when done
            });
    }, [selectedRows]);
    return (
        <div
            className="d-grid__clz h-100"
            style={{ gridTemplateColumns: "220px 1fr" }}
        >
            <Nav
                tabs
                className="d-flex flex-column"
                style={{ backgroundColor: mainColor }}
            >
                {tabs.map((tab, index) => (
                    <NavItem
                        key={index}
                        className={classNames("pb-1", tab.customClassName)}
                    >
                        <NavLink
                            className={classNames(
                                "border-0 menu-wrapper-nav-link__clz",
                                {
                                    "active active__clz": activeTab === index,
                                }
                            )}
                            onClick={() => handleTabClick(index)}
                        >
                            <span className="px-2">{tab.title}</span>
                        </NavLink>
                        {openSubmenuTab === index &&
                            tab.subMenu?.map((subTab, subMenuIndex) => (
                                <NavLink
                                    key={subMenuIndex}
                                    className={classNames(
                                        "border-0 text-nowrap pl-5 menu-wrapper-nav-link__clz",
                                        {
                                            "active sub-menu-wrapper-items-nav-link-active__clz":
                                                activeSubTab === subMenuIndex,
                                        }
                                    )}
                                    onClick={() =>
                                        handleSubmenuClick(subMenuIndex)
                                    }
                                >
                                    <span>{subTab.title}</span>
                                </NavLink>
                            ))}
                    </NavItem>
                ))}
            </Nav>

            <TabContent
                activeTab={activeTab}
                style={{
                    overflow: "hidden",
                    overflowY: "auto",
                    position: "revert",
                }}
            >
                {tabs.map((tab, index) => {
                    if (tab.subMenu && openSubmenuTab === index) {
                        return tab.subMenu.map(({ content }, subMenuIndex) => (
                            <TabPane
                                tabId={index}
                                className={classNames({
                                    "d-none": subMenuIndex !== activeSubTab,
                                })}
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    overflow: "hidden",
                                }}
                                key={subMenuIndex}
                            >
                                {content}
                            </TabPane>
                        ));
                    }
                    return (
                        <TabPane
                            tabId={index}
                            key={index}
                            className={classNames("h-100", {
                                "design-active-tab__clz": activeTab === 3,
                            })}
                        >
                            {tab.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
}
