import React from "react";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { MdCloudUpload } from "react-icons/md";
import { Card } from "reactstrap";
import { TrashIcon } from "@aureskonnect/react-ui";

import { setCountryKeyValue, store } from "../store";

type ImageUploaderPropsType = {
    uploadedImage: string | Blob;
    setUploadedImage: React.Dispatch<React.SetStateAction<string | Blob>>;
    id?: string;
    setIsEdited: Function;
    setIsNotImg: Function;
    setSelectImage: Function;
    borderColor: string;
    countryKey: string;
};

export function ImageUploader({
    uploadedImage,
    setUploadedImage,
    id = "",
    setIsEdited,
    setIsNotImg,
    setSelectImage,
    borderColor,
    countryKey,
}: ImageUploaderPropsType) {
    const { countryKeyValue } = useSnapshot(store);

    const [dragging, setDragging] = React.useState(false);

    function onFileChange(e: any) {
        if (
            e.target.files[0].type !== "image/png" &&
            e.target.files[0].type !== "image/jpg" &&
            e.target.files[0].type !== "image/jpeg"
        ) {
            setUploadedImage("");
            setSelectImage(false);
            setIsNotImg(true);
            setCountryKeyValue("");
        } else {
            setIsNotImg(false);
            setSelectImage(false);
            setUploadedImage(e.target.files[0]);
            setIsEdited(true);
        }
    }

    const handleClick = () => {
        setUploadedImage("");
        setCountryKeyValue(countryKey);
    };

    return (
        <div
            className="d-flex flex-wrap justify-content-center align-items-center lan_inp_FRflag"
            style={{
                border: dragging ? "dashed grey 4px" : "unset",
                borderRadius: "6px",
                height: "100%",
                width: "auto",
                paddingTop: "1rem",
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDragging(false);
            }}
            onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDragging(true);
            }}
            onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                    e.dataTransfer.files[0].type !== "image/png" &&
                    e.dataTransfer.files[0].type !== "image/jpeg"
                        ? toast.error("Only images are accepted")
                        : setUploadedImage(e.dataTransfer.files[0]);
                }
                setDragging(false);
            }}
        >
            <Card
                className="uploader__clz"
                style={{
                    borderColor: borderColor,
                }}
            >
                {uploadedImage ? (
                    <React.Fragment>
                        <div
                            className=" d-flex justify-content-center align-items-center"
                            style={{
                                height: "70%",
                                width: "100%",
                            }}
                        >
                            <img
                                src={
                                    typeof uploadedImage === "string"
                                        ? uploadedImage
                                        : URL.createObjectURL(uploadedImage)
                                }
                                alt=""
                                width="200px"
                                height="100px"
                            />
                        </div>
                        <div className=" d-flex justify-content-around">
                            <TrashIcon
                                style={{ cursor: "pointer" }}
                                height={25}
                                width={25}
                                fill="red"
                                onClick={handleClick}
                            />
                        </div>
                    </React.Fragment>
                ) : (
                    <label htmlFor={`file-${id}`} style={{ cursor: "pointer" }}>
                        {countryKeyValue !== "" &&
                        countryKeyValue !== undefined ? (
                            <img
                                src={
                                    uploadedImage !== ""
                                        ? uploadedImage
                                        : `https://flagpedia.net/data/flags/h80/${countryKeyValue.toLowerCase()}.webp`
                                }
                                alt=""
                                width="200px"
                                height="100px"
                            />
                        ) : (
                            <MdCloudUpload
                                style={{
                                    width: "139px",
                                    height: "165px",
                                    minHeight: "95px",
                                    maxHeight: "106px",
                                    color: "rgba(81, 77, 77, 0.85)",
                                }}
                            />
                        )}
                        <input
                            type="file"
                            id={`file-${id}`}
                            name="imgCollection"
                            onChange={onFileChange}
                            accept="image/*"
                            style={{ display: "none" }}
                        />
                    </label>
                )}
            </Card>
        </div>
    );
}
