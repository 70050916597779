import React from "react";
import { Tooltip } from "@mui/material";
import ReactSwitch from "react-switch";
import { CheckboxIcon, HeartIcon, PencilIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { toast } from "react-toastify";

import { store } from "../store";
import { store as globalStore } from "@components//VerticalLayout/store";

export function CustomIcons({
    e,
    setDataIsUpdated,
    dataIsUpdated,
    setIsAddLanguageClicked,
    setRowData,
}: any): JSX.Element {
    const { t } = useTranslation();
    const { franchiseID } = useSnapshot(globalStore);
    const { defaultSetting } = useSnapshot(store);

    const handlePencilClick = () => {
        setIsAddLanguageClicked(true);
        setRowData(e.selectedRow);
    };

    const handleChange = (checked: boolean, activation: boolean) => {
        const updatedRows = defaultSetting.language?.data?.map((row: any) => {
            if (row.Id === e.selectedRow.Id) {
                return activation
                    ? { ...row, status: checked }
                    : { ...row, isFavorite: true };
            }
            return activation ? row : { ...row, isFavorite: false };
        });
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/defaultSetting/data`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                franchiseId: franchiseID,
                types: ["language"],
                data: updatedRows,
            }),
        };
        try {
            mutate(
                apiUrl,
                fetch(apiUrl, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        } else {
                            setDataIsUpdated(!dataIsUpdated);
                            toast.success(
                                `${t(
                                    activation
                                        ? checked
                                            ? "The language was successfully activated"
                                            : "The language was successfully deactivated"
                                        : "Favorite state has been enabled successfully"
                                )}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        }
                    })
            );
        } catch (e) {}
    };

    return (
        <div className="d-flex justify-content-around">
            <Tooltip
                title={
                    e.selectedRow.country === "" ||
                    e.selectedRow.display_name === ""
                        ? ""
                        : e.selectedRow.status === false
                        ? t("Activate")
                        : t("Unactivate")
                }
                arrow
            >
                <div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                        checked={e.selectedRow.status}
                        onChange={() => {
                            handleChange(!e.selectedRow.status, true);
                        }}
                        disabled={
                            e.selectedRow.country === "" ||
                            e.selectedRow.display_name === ""
                        }
                    />
                </div>
            </Tooltip>
            <div className="ml-3">
                <Tooltip
                    title={
                        e.selectedRow.isFavorite === true
                            ? ""
                            : t("Enable favorite state")
                    }
                    arrow
                >
                    <div>
                        <HeartIcon
                            onClick={() => {
                                if (e.selectedRow.isFavorite === false)
                                    handleChange(true, false);
                            }}
                            height={25}
                            width={25}
                            name="heart"
                            fill={e.selectedRow.isFavorite ? "red" : "black"}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                </Tooltip>
            </div>
            <div className="ml-3">
                <Tooltip title={t("To modify")} arrow>
                    <div>
                        <PencilIcon
                            onClick={handlePencilClick}
                            height={25}
                            width={25}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}
