import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledTextInput,
    CheckboxIcon,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { store } from "@components/VerticalLayout/store";
import { store as localStore } from "../store";
import { setIsEdited } from "@store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";

import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import MultiCustomCreatableSelection from "@components/Common/MultiCustomCreatableSelection";
import MultiCustomSelect from "@components/Common/MultiCustomSelect";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { languages } from "@constants/index";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type ModeOfSaleFormType = {
    setIsAddModeOfSaleClicked: Function;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    dataSale: React.SetStateAction<any>;
    dataIsUpdated: boolean | number;
    data: any;
    setRowData: Function;
    isAddModeOfSaleClicked: boolean;
};

export default function SaleForm({
    setIsAddModeOfSaleClicked,
    setDataIsUpdated,
    dataSale,
    dataIsUpdated,
    data,
    setRowData,
    isAddModeOfSaleClicked,
}: ModeOfSaleFormType) {
    const { t } = useTranslation();
    const { defaultSetting } = useSnapshot(localStore);
    const translatedSaleMethod: any = {
        Takeaway: {
            fr: "Emporter",
            en: "Takeaway",
            nl: "Mitnehmen",
            es: "Para llevar",
            pt: "Para levar",
            it: "Da asporto",
        },
        "In delivery": {
            fr: "En livraison",
            en: "In delivery",
            nl: "Lieferung",
            es: "Entrega a domicilio",
            pt: "Entrega ao domicílio",
            it: "Consegna a domicilio",
        },
        "Dine-in": {
            fr: "Sur place",
            en: "Dine-in",
            nl: "Vor-Ort-Verzehr",
            es: "Para comer en el local",
            pt: "Para comer no local",
            it: "Sul posto",
        },
        "Click and Collect": {
            fr: "Retrait C&C",
            en: "Click and Collect",
            nl: "Online-Bestellung und Abholung",
            es: "Compra y recogida en tienda",
            pt: "Compra e recolha na loja",
            it: "Clicca e ritira",
        },
        "Drive-through": {
            fr: "Drive",
            en: "Drive-through",
            nl: "Drive-through",
            es: "Drive-through",
            pt: "Drive-through",
            it: "Drive-through",
        },
        Restaurant: {
            fr: "Restaurant",
            en: "Restaurant",
            nl: "Restaurant",
            es: "Restaurante",
            pt: "Restaurante",
            it: "Ristorante",
        },
    };
    const { franchiseID } = useSnapshot(store);
    const [saleMode, setSaleMode] = React.useState<string>("");
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>("");
    const [tagsData, setTagsData] = React.useState<any>([]);
    const [salesSupportData, setSalesSupportData] = React.useState<any>([]);
    const [displayName, setDisplayName] = React.useState<string>("");
    const [advancedDisplayName, setAdvancedDisplayName] = React.useState<any>({
        languages: {
            fr: "",
            en: "",
            nl: "",
            es: "",
            pt: "",
            it: "",
        },
    });
    const [salesSupport, setSalesSupport] = React.useState<any>([]);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const selectedLanguage: any = languages.find(
        (el: any) => el.value === i18nextLng
    );

    async function editModeOfSale() {
        if (
            dataSale.advancedDisplayName.languages[i18nextLng] ===
                displayName &&
            JSON.parse(JSON.stringify(salesSupport)).join(",") ===
                dataSale.support_vente.join(",") &&
            dataSale.tag.join(",") === tagsData.join(",")
        ) {
            setIsModalOpened(true);
        } else {
            toast.dismiss();
            const updatedRows = data.map((row: any) => {
                if (row.Id === dataSale.Id) {
                    return {
                        ...row,
                        tag: tagsData,
                        display_name: displayName,
                        support_vente: salesSupport,
                        advancedDisplayName: advancedDisplayName,
                        status:
                            displayName === "" || salesSupport === ""
                                ? false
                                : row.status,
                    };
                }
                return { ...row };
            });
            const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/defaultSetting/data`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    franchiseId: franchiseID,
                    types: ["saleMode"],
                    data: updatedRows,
                }),
            };
            try {
                mutate(
                    apiUrl,
                    fetch(apiUrl, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            } else {
                                setDataIsUpdated(!dataIsUpdated);
                                setIsAddModeOfSaleClicked(false);
                                toast.success(
                                    `${t(
                                        "The modification has been successfully completed"
                                    )}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        theme: "colored",
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                            }
                        })
                );
            } catch (e) {}
        }
    }

    const formatCreateLabel = (inputValue: any) => (
        <span>
            {inputValue} ( {t("New tag")} )
        </span>
    );

    const formatCreateLabelModeOfSale = (inputValue: any) => (
        <span>
            {inputValue} ( {t("New sales method")} )
        </span>
    );
    function updateSalesSupportData(saleMode: string) {
        let isDeliverooUsed = false;
        const combinedSalesData = defaultSetting.saleSupport.data.filter(
            (el: any) => el.status === true
        );
        isDeliverooUsed = defaultSetting.saleMode.data.some(
            (el: any) =>
                el.support_vente.includes("Deliveroo") && el.Id !== dataSale.Id
        );
        const saleSupportList = (isDeliverooUsed
            ? combinedSalesData.filter(
                  (el: any) => el.support_de_vente !== "Deliveroo"
              )
            : combinedSalesData
        ).map((elt: any) => elt.support_de_vente);

        setSalesSupportData(saleSupportList.map((el: any) => t(el)));
    }
    useEffect(() => {
        clearMultiCustomSelectionData();
        clearData();
        setTitle(t("Modify a sales method"));
        setSaleMode(dataSale["Mode of sale"]);
        setTagsData(dataSale.tag);
        setDisplayName(dataSale.advancedDisplayName.languages[i18nextLng]);
        setSalesSupport(dataSale.support_vente);
        setAdvancedDisplayName(dataSale.advancedDisplayName);
        updateSalesSupportData(dataSale["Mode of sale"]);
        // eslint-disable-next-line
    }, [dataSale, t]);
    
    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5
                        className={`${classnames("pt-3", {
                            mdv_txt_FReditSalesMode: dataSale.Id !== undefined,
                            mdv_txt_FRtitleFormModeOfSale:
                                dataSale.Id === undefined,
                        })}`}
                    >
                        {title}
                    </h5>
                    <CrossIcon
                        className="mdv_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsAddModeOfSaleClicked(false);
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5 `}
                    style={{
                        maxHeight: "470px",
                        overflowY: " auto",
                    }}
                >
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Sales method")}
                        </StyledLabel>

                        <StyledSelectInput
                            options={[]}
                            placeholder={t("Select")}
                            className="mdv_inp_FRmodeOfSale not-allowed-input__clz"
                            value={
                                saleMode === ""
                                    ? null
                                    : {
                                          label:
                                              translatedSaleMethod[saleMode][
                                                  i18nextLng
                                              ],
                                          value: saleMode,
                                      }
                            }
                            onChange={() => {}}
                            isClearable
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabelModeOfSale}
                            autoFocus={dataSale.Id === undefined}
                        />
                    </AvGroup>
                    <AvGroup>
                        <div className="d-flex justify-content-between mb-1">
                            <StyledLabel htmlFor="text" className="mt-3">
                                {t("Display name")}
                            </StyledLabel>
                        </div>
                        <StyledTextInput
                            className="mdv_inp_FRdisplayName"
                            autocomplete="off"
                            name="designation"
                            onChange={(e: any) => {
                                setDisplayName(e.target.value.trim());
                                setAdvancedDisplayName({
                                    ...advancedDisplayName,
                                    languages: {
                                        ...advancedDisplayName.languages,
                                        [selectedLanguage.value]:
                                            e.target.value,
                                    },
                                });
                            }}
                            value={
                                advancedDisplayName.languages[i18nextLng] ?? ""
                            }
                            placeholder={t("Write")}
                            type="text"
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Sales support")}
                        </StyledLabel>
                        <MultiCustomSelect
                            onDelete={() => {}}
                            result={salesSupport}
                            setResult={setSalesSupport}
                            data={salesSupportData}
                            onChange={(e: any) => {
                                // setSelectSales(false);
                            }}
                            className="mdv_inp_FRsalesSupport"
                            placeholder={`${t("Select")}`}
                            value={t("Choose")}
                            name="sales support"
                            usedSaleSupports={[]}
                            isModeOfSaleActiveInTemplate={false}
                            isDeliverooAvaible={false}
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Tag")}
                        </StyledLabel>

                        <MultiCustomCreatableSelection
                            onDelete={() => {}}
                            isSendingTag={false}
                            setResult={setTagsData}
                            result={tagsData}
                            data={[]}
                            onChange={(e: any) => {}}
                            onCreateOption={(e: any) => {}}
                            placeholder={`${t("Select")}`}
                            value={t("Choose")}
                            name="tag"
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabel}
                            max-width="100%"
                            className="mdv_inp_FRtags"
                            isDefault={true}
                        />
                        <div className="d-flex flex-wrap"></div>
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 mdv_btn_FRcancel cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            setIsAddModeOfSaleClicked(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className="mdv_btn_FRvalidate cmn_btn_validate"
                        onClick={() => {
                            editModeOfSale();
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddModeOfSaleClicked}
                setIsAddClicked={setIsAddModeOfSaleClicked}
                setEditedData={setRowData}
                setIsEdited={setIsEdited}
            />
        </React.Fragment>
    );
}
