import React from "react";

import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";

export function CustomPaymentMethodIcons({ e }: any): JSX.Element {
    return (
        <div className="d-flex justify-content-around">
            <CustomColumnDisplay
                text={e.selectedRow.support_vente.join(", ")}
            />
        </div>
    );
}
