import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledTextInput,
    StyledSelectInput,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import classnames from "classnames";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import { setIsEdited } from "@store";

import { searchPosInArrayObject } from "@helpers/general";
import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import MultiCustomCreatableSelection from "@components/Common/MultiCustomCreatableSelection";
import MultiCustomSelect from "@components/Common/MultiCustomSelect";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { editCurrency } from "./helper";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type ModeOfSaleFormType = {
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    devises: any;
    setIsAddDeviceClicked: Function;
    data: any;
    dataIsUpdated: boolean | number;
    isAddDeviceClicked: boolean;
    setRowData: Function;
};

export default function CurrencyForm({
    setIsAddDeviceClicked,
    setDataIsUpdated,
    devises,
    data,
    dataIsUpdated,
    isAddDeviceClicked,
    setRowData,
}: ModeOfSaleFormType) {
    const { t } = useTranslation();

    const { franchiseID, oneShop, shopPays } = useSnapshot(store);

    const [selectCountry, setSelectCountry] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    const [designation, setDesignation] = React.useState<string>("");
    const [currencies, setCurrencies] = React.useState<any>([]);
    const [symbol, setSymbol] = React.useState<string>("");
    const [decimalNumber, setDecimalNumber] = React.useState<string>("");
    const [decimalDesignation, setDecimalDesignation] = React.useState<string>(
        ""
    );
    const [countryList, setCountryList] = React.useState<any>([]);
    const [countryData, setCountryData] = React.useState<any>([]);
    const [tagsData, setTagsData] = React.useState<any>([]);
    const [change, setChange] = React.useState<string>("");
    const [
        inputdesignationDecimal,
        setInputdesignationDecimal,
    ] = React.useState<boolean>(false);
    const [inputChange, setInputChange] = React.useState<boolean>(false);
    const [inputdecimalNumber, setInputDecimalNumber] = React.useState<boolean>(
        false
    );
    const [title, setTitle] = React.useState<string>("");
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    async function refreshPays() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/pays`;

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                        "Accept-Language": i18nextLng,
                    },
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error!");
                        }
                        let listOfTag: any = [];

                        data.data.forEach((element: any) => {
                            listOfTag.push({
                                label: element["Designation"],
                                value: element.Id,
                                frDesignation: element.frenchDesignation,
                            });
                        });
                        if (oneShop) {
                            let shopPaysItems =
                                listOfTag[
                                    searchPosInArrayObject(
                                        listOfTag,
                                        "label",
                                        shopPays
                                    )
                                ];
                            setCountryList([shopPaysItems?.label]);
                        } else {
                            setCountryList(
                                data.data.map((el: any) => el.Designation)
                            );
                        }
                    })
            );
        } catch (e: any) {}
    }

    async function getCurrencies() {
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/currencies/symbol`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    setCurrencies(
                        result.data.map((el: any) => {
                            return { label: el.key, value: el.Symbole };
                        })
                    );
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    async function editDevice() {
        if (
            designation === devises["Nom d'affichage"] &&
            decimalNumber === devises["Nbr décimale"] &&
            decimalDesignation === devises["Désignation décimale"] &&
            countryData.join(",") === devises.Pays &&
            tagsData.join(",") === devises[t("Tag")]
        ) {
            setIsModalOpened(true);
        } else {
            const updatedRows = data.map((row: any) => {
                if (row.Id === devises.Id) {
                    return {
                        ...row,
                        "Nom d'affichage": designation,
                        Tag: tagsData.join(","),
                        "Nbr décimale": decimalNumber,
                        "Désignation décimale": decimalDesignation,
                        Pays: countryData.join(","),
                        status:
                            designation === "" ||
                            decimalNumber === "" ||
                            decimalDesignation === "" ||
                            countryData.length === 0
                                ? false
                                : row.status,
                    };
                }
                return { ...row };
            });
            let result: any = editCurrency(
                franchiseID,
                updatedRows,
                setDataIsUpdated,
                dataIsUpdated,
                setIsAddDeviceClicked
            );
            if (result === true)
                toast.success(
                    `${t("The modification has been successfully completed")}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
        }
    }

    const formatCreateLabel = (inputValue: any) => (
        <span>
            {inputValue} ({t("New tag")})
        </span>
    );

    useEffect(() => {
        clearData();
        setTitle(t("Modify a currency"));
        setDesignation(devises[t("Display name")]);
        setSymbol(devises[t("Symbol")]);
        setDecimalNumber(devises[t("Nbr decimal")]);
        setDecimalDesignation(devises[t("Decimal designation")]);
        setCountryData(devises[t("Country")]?.split(","));
        setChange(devises[t("Currency")]);
        setTagsData(devises[t("Tag")]?.split(","));
        getCurrencies();

        // eslint-disable-next-line
    }, [devises.id, t]);

    React.useEffect(() => {
        refreshPays();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (Object.values(devises).length !== 0) {
            if (devises[t("Display name")] !== "") {
                setInputDesignation(false);
            }
            if (devises[t("Currency")] !== "") {
                setInputChange(false);
            }
            if (countryData?.length !== 0) {
                setSelectCountry(false);
            }
            if (decimalNumber !== "") {
                setInputDecimalNumber(false);
            }
            if (decimalDesignation !== "") {
                setInputdesignationDecimal(false);
            }
        }
        // eslint-disable-next-line
    }, [devises]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5 className="pt-3 dev_txt_FRtitleFormCurrency">
                        {title}
                    </h5>
                    <CrossIcon
                        className="icon-close-notification__clz dev_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsAddDeviceClicked(false);
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5 `}
                    style={{
                        maxHeight: "480px",
                        overflowY: " scroll",
                    }}
                >
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Currency")}
                        </StyledLabel>

                        <StyledSelectInput
                            isClearable
                            autoFocus={devises.id === undefined}
                            className={classnames("dev_inp_FRcurrency", {
                                invalid__clz: inputChange,
                                "not-allowed-input__clz": devises.id,
                            })}
                            noOptionsMessage={() => t("No options")}
                            options={currencies}
                            autocomplete="off"
                            name="change"
                            onChange={(e: any) => {
                                if (e !== null) {
                                    setChange(e.label);

                                    setSymbol(e.value);
                                    setDesignation(e.label);
                                } else {
                                    setChange("");
                                    setSymbol("");
                                }
                                setInputChange(false);
                                setInputDesignation(false);
                            }}
                            value={
                                change === ""
                                    ? null
                                    : {
                                          label: change,
                                          value: change,
                                      }
                            }
                            placeholder={`${t("Select")}…`}
                            type="text"
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Display name")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("dev_inp_FRdisplayName", {
                                input__clz:
                                    inputDesignation ||
                                    (designation.trim() === "" &&
                                        designation !== ""),
                            })}
                            autocomplete="off"
                            name="Display name"
                            onChange={(e: any) => {
                                setDesignation(e.target.value.trim());
                                setInputDesignation(false);
                            }}
                            value={designation}
                            placeholder={t("Write")}
                            type="text"
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Symbole")}
                        </StyledLabel>
                        <StyledTextInput
                            className="not-allowed-input__clz dev_inp_FRsymbol"
                            autocomplete="off"
                            name="symbol"
                            value={symbol}
                            placeholder={t("Symbole")}
                            type="text"
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Decimal designation")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames(
                                "dev_inp_FRdecimalDesignation",
                                {
                                    input__clz:
                                        inputdesignationDecimal ||
                                        (decimalDesignation.trim() === "" &&
                                            decimalDesignation !== ""),
                                }
                            )}
                            autocomplete="off"
                            name="decimal designation"
                            onChange={(e: any) => {
                                setDecimalDesignation(e.target.value.trim());
                                setInputdesignationDecimal(false);
                            }}
                            value={decimalDesignation}
                            placeholder={t("Write")}
                            type="text"
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Decimal number")}
                        </StyledLabel>
                        <StyledSelectInput
                            maxMenuHeight="80px"
                            placeholder={`${t("Select")}…`}
                            className={classnames("dev_inp_FRdecimalNumber", {
                                invalid__clz: inputdecimalNumber,
                            })}
                            value={
                                decimalNumber === ""
                                    ? null
                                    : {
                                          label: decimalNumber,
                                          value: decimalNumber,
                                      }
                            }
                            onChange={(e: any) => {
                                setInputDecimalNumber(false);
                                e !== null
                                    ? setDecimalNumber(e.label)
                                    : setDecimalNumber("");
                            }}
                            isClearable
                            options={[
                                {
                                    label: "1",
                                    value: "1",
                                },
                                {
                                    label: "2",
                                    value: "2",
                                },
                                {
                                    label: "3",
                                    value: "3",
                                },
                                {
                                    label: "4",
                                    value: "4",
                                },
                            ]}
                            name="mark"
                            noOptionsMessage={() => t("No options")}
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Country")}
                        </StyledLabel>
                        <MultiCustomSelect
                            onDelete={(e: any) => {}}
                            result={countryData}
                            setResult={setCountryData}
                            data={countryList}
                            onChange={(e: any) => {
                                setSelectCountry(false);
                            }}
                            className={classnames("dev_inp_FRcountry", {
                                invalid__clz: selectCountry,
                            })}
                            blocked={false}
                            placeholder={`${t("Select")}…`}
                            blockedList={countryList && devises.id}
                            value={t("Choose")}
                            name="country"
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Tag")}
                        </StyledLabel>
                        <MultiCustomCreatableSelection
                            onDelete={() => {}}
                            isSendingTag={false}
                            setResult={setTagsData}
                            result={tagsData}
                            className="dev_inp_FRtags"
                            data={[]}
                            onChange={(e: any) => {}}
                            onCreateOption={(e: any) => {}}
                            placeholder={`${t("Select")}…`}
                            value={t("Choose")}
                            name="tag"
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabel}
                            isDefault={true}
                        />
                        <div className="d-flex flex-wrap"></div>
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 dev_btn_FRcancel cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            setIsAddDeviceClicked(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className={`dev_btn_FRvalidate cmn_btn_validate`}
                        disabled={false}
                        onClick={editDevice}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddDeviceClicked}
                setIsAddClicked={setIsAddDeviceClicked}
                setEditedData={setRowData}
                setIsEdited={setIsEdited}
            />
        </React.Fragment>
    );
}
