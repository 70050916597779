import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { SaveButtonComponent } from "./SaveButtonComponent";
import { ConfigElement } from "./ConfigElement";
import { ModalHeaderComponent } from "./ModalHeaderComponent";

type PropsType = {
    setIsOpen: (value: boolean) => void;
    isOpen: boolean;
    setIsLoading: Function;
    setNumberClick: Function;
};

export function DefaultSettingModal({
    setIsOpen,
    isOpen,
    setIsLoading,
    setNumberClick,
}: PropsType): JSX.Element {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                isOpen={isOpen}
            >
                <ModalHeaderComponent
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    setNumberClick={setNumberClick}
                />
                <ModalBody className="ml-2">
                    <ConfigElement />
                </ModalBody>
                <ModalFooter>
                    <CustomSecondaryColorButton
                        className="w-20 kds_btn_validateTemplate"
                        rounded
                        variant="primary"
                        onClick={() => {
                            setIsOpen(!isOpen);
                            setNumberClick(1);
                        }}
                    >
                        {t("Annuler")}
                    </CustomSecondaryColorButton>
                    <SaveButtonComponent
                        setIsOpen={setIsOpen}
                        isOpen={isOpen}
                        setIsLoading={setIsLoading}
                    />
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
