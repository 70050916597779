import { proxy } from "valtio";

type StoreType = {
    defaultSetting: any;
    isPaymentModified: boolean;
    isSaleModeModified: boolean;
    isSaleSupportModified: boolean;
    saleMode: any
    paymentMethod: any
};

export const store = proxy<StoreType>({
    defaultSetting: {
        tva: {},
        language: {},
        saleSupport: {},
        saleMode: {},
        paymentMethod: {},
        currency: {},
        applications: {},
        peripheral: {},
    },
    isPaymentModified: false,
    isSaleModeModified: false,
    isSaleSupportModified: false,
    saleMode: [],
    paymentMethod: []
});
async function loadDefaultSettings() {
    try {
        const keys = [
            "tva",
            "language",
            "saleSupport",
            "saleMode",
            "paymentMethod",
            "currency",
            "applications",
            "peripheral",
        ];

        const fetchPromises = keys.map((key) =>
            fetch(`/DefaultSetting/${key}.json`).then((res) => res.json())
        );

        const values = await Promise.all(fetchPromises);

        // Assign fetched data to the store
        keys.forEach((key, index) => {
            store.defaultSetting[key] = values[index];
        });

    } catch (error) {
        console.error("Error loading default settings:", error);
    }
}
loadDefaultSettings()
export function setDefaultSetting(defaultSetting: any): void {
    store.defaultSetting = defaultSetting;
}
export function setIsPaymentModified(): void {
    store.isPaymentModified = !store.isPaymentModified
}
export function setIsSaleModeModified(): void {
    store.isSaleModeModified = !store.isSaleModeModified
}
export function setSaleMode(sale: any): void {
    store.saleMode = sale
}
export function setPayment(payment: any): void {
    store.paymentMethod = payment
}
export function setIsSaleSupportModified(isSaleSupportModified: boolean): void {
    store.isSaleSupportModified = isSaleSupportModified
}

export default store;
