
import { mutate } from "swr";

export async function fetchCountry(
    i18nextLng: string,
    setCountryOptions: Function,
    t: any
) {
    let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/pays`;
    try {
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    "Accept-Language": i18nextLng,
                },
                method: "GET",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("Error!");
                    }
                    let listOfCountry: any = [];
                    data.data.forEach((element: any) => {
                        listOfCountry.push({
                            label: element["Designation"],
                            value: element.Id,
                            code: element[t("code")],
                        });
                    });
                    setCountryOptions(listOfCountry);
                })
        );
    } catch (e: any) { }
}

