import React, { useState } from "react";
import { CheckboxIcon, CrossIcon, StyledLabel } from "@aureskonnect/react-ui";
import { Input } from "reactstrap";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import storeVatRate, { setIsEdited } from "@pages/SettingGeneral/VatRate/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import { store } from "@components/VerticalLayout/store";
import { setCanValidate } from "@pages/SettingGeneral/ModeOfInformation/store";

import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import { searchPosInArrayObject } from "@helpers/general";
import MultiCustomCreatableSelection from "@components/Common/MultiCustomCreatableSelection";
import MultiCustomSelect from "@components/Common/MultiCustomSelect";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { editVateRate } from "./helper";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type vatRateFormType = {
    dataVatRate: any;
    setEditedData: Function;
    setIsAddVatRateClicked: Function;
    pays: any;
    setDataIsUpdated: Function;
    dataIsUpdated: boolean | number;
    data: any;
    isAddVatRateClicked: boolean;
    setRowData: Function;
};

export default function VatRateForm({
    dataVatRate,
    setEditedData,
    setIsAddVatRateClicked,
    pays,
    setDataIsUpdated,
    dataIsUpdated,
    data,
    isAddVatRateClicked,
    setRowData,
}: vatRateFormType) {
    const { t } = useTranslation();
    const { oneShop, shopPays, franchiseID } = useSnapshot(store);
    const { paysVateRate } = useSnapshot(storeVatRate);

    const [isValidVatRate, setIsValidVatRate] = React.useState<boolean>(false);

    const [codeVateRate, setCodeVateRate] = useState<string>("");

    const [inputCodeVateRate, setInputCodeVateRate] = useState<boolean>(false);
    const [inputCodeVateRateExist, setInputCodeVateRateExist] = useState<
        boolean
    >(false);
    const [vateRate, setVateRate] = useState<string>("");
    const [inputPaysVateRate, setInputPaysVateRate] = useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
    const [paysVateRateItem, setPaysVateRateItem] = useState<any>([]);
    const [paysVateRateData, setPaysVateRateData] = useState<any>([]);

    const [tagVateRateItem, setTagVateRateItem] = useState<any>([]);

    function searchPosInArrayObjectVatRateUppercase(array: any, req: any) {
        let pos = -1;
        if (array.length > 0) {
            array.forEach((el: any, i: number) => {
                if (
                    el.code.toUpperCase() === req.code.toUpperCase() &&
                    el.value.toUpperCase() === req.value.toUpperCase()
                ) {
                    pos = i;
                }
            });
        }
        return pos;
    }
    const handleEdit = () => {
        if (
            dataVatRate[t("Country")].labels.join(",") ===
                paysVateRateData.join(",") &&
            vateRate === dataVatRate.value.replace(",", ".") &&
            codeVateRate === dataVatRate.code.replace(",", ".") &&
            tagVateRateItem.join(",") === dataVatRate.tag.join(",")
        ) {
            setIsModalOpened(true);
        } else {
            toast.dismiss();
            if (!inputCodeVateRateExist) {
                const updatedRows = data.map((row: any) => {
                    if (row.Id === dataVatRate.Id) {
                        return {
                            ...row,
                            "Taux TVA": vateRate,
                            code: codeVateRate.trim(),
                            "Code TVA": codeVateRate.trim(),
                            value: vateRate,
                            tag: tagVateRateItem,
                            status:
                                vateRate === "" || codeVateRate === ""
                                    ? false
                                    : row.status,
                            pays: oneShop
                                ? row.pays
                                : paysVateRateItem.map((a: any) => a.value),
                            Pays: oneShop
                                ? row.Pays
                                : {
                                      labels: paysVateRateData,
                                      ids: paysVateRateItem.map(
                                          (a: any) => a.value
                                      ),
                                  },
                        };
                    }
                    return { ...row };
                });
                editVateRate(
                    franchiseID,
                    updatedRows,
                    dataIsUpdated,
                    setDataIsUpdated,
                    setIsAddVatRateClicked
                );
                toast.success(
                    `${t("The modification has been successfully completed")}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            }
        }
    };

    const formatCreateLabel = (inputValue: any) => (
        <span>
            {inputValue} ({t("New tag")})
        </span>
    );

    React.useEffect(() => {
        let paysOfVatRateItems: any = [];
        if (paysVateRateData.length > 0) {
            paysVateRateData.forEach((el: any) => {
                if (searchPosInArrayObject(paysVateRate, "label", el) !== -1) {
                    paysOfVatRateItems.push(
                        paysVateRate[
                            searchPosInArrayObject(paysVateRate, "label", el)
                        ]
                    );
                } else {
                }
            });
        }

        setPaysVateRateItem(
            paysVateRateData.length > 0
                ? pays.filter(
                      (x: any) => paysVateRateData.indexOf(x.label) !== -1
                  )
                : []
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paysVateRateData, dataVatRate]);
    React.useEffect(() => {
        if (dataVatRate?.Id !== undefined) {
            setPaysVateRateData(dataVatRate[t("Country")].labels);
            clearData();
            clearMultiCustomSelectionData();
            setCodeVateRate(dataVatRate.code.replace(",", "."));
            setVateRate(dataVatRate.value.replace(",", "."));
            setTagVateRateItem(dataVatRate.tag);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVatRate]);
    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5
                        className={`${classnames(
                            "pt-3 tva_txt_FRtitleFormVatRate",
                            {
                                tva_txt_FRmodifyVatRate:
                                    dataVatRate.length !== 0,
                            }
                        )}`}
                    >
                        {t("Modify VAT rate")}
                    </h5>
                    <CrossIcon
                        style={{ cursor: "pointer" }}
                        className="tva_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsAddVatRateClicked(false);
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5 `}
                    style={{ overflowY: "auto", maxHeight: "470px" }}
                >
                    <AvGroup>
                        <StyledLabel htmlFor="path" className="mt-3 ">
                            {t("VAT code")}
                        </StyledLabel>
                        <Input
                            name="code"
                            className={classnames("tva_inp_FRvatCode ", {
                                input__clz:
                                    inputCodeVateRate || inputCodeVateRateExist,
                            })}
                            placeholder={t("Write")}
                            type="text"
                            autocomplete="off"
                            value={codeVateRate}
                            onChange={(e: any) => {
                                let pos = searchPosInArrayObjectVatRateUppercase(
                                    data,
                                    {
                                        code: e.target.value.trim(),
                                        value: vateRate.trim(),
                                    }
                                );
                                if (
                                    (pos !== -1 &&
                                        typeof dataVatRate !== "object") ||
                                    (pos !== -1 &&
                                        e.target.value.trim() !==
                                            dataVatRate[t("VAT code")].trim())
                                ) {
                                    setInputCodeVateRateExist(true);
                                } else {
                                    setInputCodeVateRateExist(false);
                                }

                                setIsEdited(true);
                                setCodeVateRate(e.target.value);
                                setCanValidate(false);

                                setInputCodeVateRate(false);
                            }}
                            autoFocus={
                                oneShop && typeof dataVatRate === "object"
                                    ? false
                                    : true
                            }
                        />
                        {inputCodeVateRateExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t(
                                    "The combination VAT code, VAT rate already exists"
                                )}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="path" className="mt-3 ">
                            {t("VAT rate")}
                        </StyledLabel>
                        <Input
                            name="value"
                            className={classnames("tva_inp_FRvatRate ", {
                                input__clz:
                                    inputCodeVateRateExist || isValidVatRate,
                            })}
                            placeholder={t("Write")}
                            type="number"
                            step="0.00"
                            min="0"
                            onKeyPress={(e) => {}}
                            value={vateRate}
                            autocomplete="off"
                            onChange={(e: any) => {
                                if (
                                    (Number(e.target.value.trim()) >= 0 &&
                                        Number(e.target.value.trim()) <=
                                            100) === false
                                ) {
                                    setIsValidVatRate(true);
                                } else {
                                    setIsValidVatRate(false);
                                }
                                setVateRate(
                                    e.target.value !== ""
                                        ? Number(e.target.value).toString()
                                        : ""
                                );

                                if (data.length > 0) {
                                    let pos = searchPosInArrayObjectVatRateUppercase(
                                        data,

                                        {
                                            code: codeVateRate.trim(),
                                            value: Number(e.target.value)
                                                .toString()
                                                .trim(),
                                        }
                                    );

                                    if (
                                        (pos !== -1 &&
                                            typeof dataVatRate !== "object") ||
                                        (pos !== -1 &&
                                            typeof dataVatRate === "object" &&
                                            e.target.value.trim() !==
                                                dataVatRate[
                                                    t("VAT rate")
                                                ].trim())
                                    ) {
                                        setInputCodeVateRateExist(true);
                                    } else {
                                        setInputCodeVateRateExist(false);
                                    }
                                }
                            }}
                        />
                        {isValidVatRate ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Vat rate is invalid")}
                            </div>
                        ) : null}
                        {inputCodeVateRateExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t(
                                    "The combination VAT code, VAT rate already exists"
                                )}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="path"
                            className="required__clz mt-3 "
                        >
                            {t("Country")}
                        </StyledLabel>
                        <MultiCustomSelect
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            blocked={oneShop && typeof dataVatRate === "object"}
                            result={paysVateRateData}
                            setResult={setPaysVateRateData}
                            data={
                                oneShop
                                    ? [
                                          pays[
                                              searchPosInArrayObject(
                                                  pays,
                                                  "label",
                                                  shopPays
                                              )
                                          ]?.label,
                                      ]
                                    : pays.map((a: any) => a.label)
                            }
                            onChange={(e: any) => {
                                setIsEdited(true);

                                setInputPaysVateRate(false);
                            }}
                            className={classnames("tva_inp_FRcountry ", {
                                invalid__clz: inputPaysVateRate,
                            })}
                            placeholder={`${t("Select")}…`}
                            value={t("Choose")}
                            name="country"
                            blockedList={dataVatRate.Pays.labels}
                            hideCross={true}
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="path" className="mt-3 ">
                            {t("Tag")}
                        </StyledLabel>
                        <MultiCustomCreatableSelection
                            isSendingTag={false}
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            onCreateOption={async (e: any) => {
                                setIsEdited(true);
                            }}
                            className="tva_inp_FRtags"
                            setResult={setTagVateRateItem}
                            result={tagVateRateItem}
                            data={[]}
                            onChange={(e: any) => {
                                setIsEdited(true);

                                setTagVateRateItem([]);
                            }}
                            placeholder={`${t("Select")}…`}
                            value={""}
                            name="tag"
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabel}
                        />
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        variant="light"
                        className="mr-2 tva_btn_FRcancel cmn_btn_cancel"
                        onClick={() => {
                            setIsEdited(false);
                            setEditedData([]);
                            setIsAddVatRateClicked(false);
                        }}
                    >
                        <span>{t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className={`cmn_btn_validate ${classnames(
                            "tva_btn_FRvalidate"
                        )}`}
                        variant="primary"
                        onClick={handleEdit}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddVatRateClicked}
                setIsAddClicked={setIsAddVatRateClicked}
                setEditedData={setRowData}
                setIsEdited={setIsEdited}
            />
        </React.Fragment>
    );
}
