import React, { Fragment, useState } from "react";
import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { store as globalStore } from "@components/VerticalLayout/store";

import { CustomIcons } from "./CustomIcons";
import CurrencyForm from "./CurrencyForm";

export function Currency() {
    const { t } = useTranslation();
    const { franchiseID } = useSnapshot(globalStore);

    const [filterActive, setLocalFilterActive] = useState<boolean>(false);
    const [isAddCurrencyClicked, setIsAddCurrencyClicked] = useState<boolean>(
        false
    );
    const [dataIsUpdated, setDataIsUpdated] = useState<boolean | number>(false);
    const [data, setData] = useState<any>({});
    const [rowData, setRowData] = useState<any>({});
    const [url, setUrl] = useState<any>("/DefaultSetting/currency.json");

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 1,
            columnName: t("Display name"),
            customJsx: (e: any) => (
                <Fragment>{e.selectedRow["Nom d'affichage"]}</Fragment>
            ),
        },
        {
            indexOFColumn: 2,
            columnName: t("Currency"),
            customJsx: (e: any) => (
                <Fragment>{e.selectedRow["Devise"]}</Fragment>
            ),
        },
        {
            indexOFColumn: 3,
            columnName: t("Symbol"),
            customJsx: (e: any) => (
                <Fragment>{e.selectedRow["Symbole"]}</Fragment>
            ),
        },
        {
            indexOFColumn: 4,
            columnName: t("Nbr decimal"),
            customJsx: (e: any) => (
                <Fragment>{e.selectedRow["Nbr décimale"]}</Fragment>
            ),
        },
        {
            indexOFColumn: 5,
            columnName: t("Decimal designation"),
            customJsx: (e: any) => (
                <Fragment>{e.selectedRow["Désignation décimale"]}</Fragment>
            ),
        },
        {
            indexOFColumn: 6,
            columnName: t("Country"),
            customJsx: (e: any) => <Fragment>{e.selectedRow["Pays"]}</Fragment>,
        },
        {
            indexOFColumn: 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setIsAddCurrencyClicked={setIsAddCurrencyClicked}
                    setRowData={setRowData}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    setIsAddDeviceClicked={setIsAddCurrencyClicked}
                />
            ),
            disableFilter: false,
        },
    ];

    React.useEffect(() => {
        setUrl(
            `${process.env.REACT_APP_API_V2_URL}/settings/defaultSetting/getData?type=currency&franchiseId=${franchiseID}`
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h2 className="mt-3 ml-3">{t("Currency")}</h2>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: isAddCurrencyClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    canMovedCheckboxLeftOnExpand
                    url={url}
                    setData={setData}
                    canSelect
                    customSelect
                    canResize
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight={"450px"}
                    maxHeight={"450px"}
                    name="tva"
                />
                {isAddCurrencyClicked ? (
                    <CurrencyForm
                        setIsAddDeviceClicked={setIsAddCurrencyClicked}
                        setDataIsUpdated={setDataIsUpdated}
                        devises={rowData}
                        data={data.data}
                        dataIsUpdated={dataIsUpdated}
                        isAddDeviceClicked={isAddCurrencyClicked}
                        setRowData={setRowData}
                    />
                ) : null}
            </div>
        </div>
    );
}
