import React, { useEffect, useState } from "react";
import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { setDefaultSetting, store } from "../store";
import { store as globalStore } from "@components/VerticalLayout/store";

import { CustomIcons } from "./CustomIcons";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { CustomPaymentMethodIcons } from "./CustomPaymentMethodIcons";
import PaymentForm from "./PaymentForm";
import { editPayment } from "./helper";

export function PaymentMethod() {
    const { t } = useTranslation();
    const { franchiseID } = useSnapshot(globalStore);
    const { defaultSetting, isPaymentModified, paymentMethod } = useSnapshot(
        store
    );
    const [skipFirstEffect, setSkipFirstEffect] = useState(false);

    const [filterActive, setLocalFilterActive] = useState<boolean>(false);
    const [isAddPaymentClicked, setIsAddPaymentClicked] = useState<boolean>(
        false
    );
    const [dataIsUpdated, setDataIsUpdated] = useState<boolean | number>(false);
    const [data, setData] = useState<any>({});
    const [rowData, setRowData] = useState<any>({});
    const [url, setUrl] = useState<any>("/DefaultSetting/paymentMethod.json");

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 1,
            columnName: t("Payment method"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay
                        text={t(e.selectedRow["Type of payment method"])}
                    />
                );
            },
        },
        {
            indexOFColumn: 0,
            columnName: t("Display name"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay
                        text={t(e.selectedRow["Display name"])}
                    />
                );
            },
        },

        {
            indexOFColumn: 2,
            columnName: t("Sales support"),
            customJsx: (e: any) => <CustomPaymentMethodIcons e={e} />,
        },
        {
            indexOFColumn: 3,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setRowData={setRowData}
                    setIsAddPaymentClicked={setIsAddPaymentClicked}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                />
            ),
        },
    ];
    useEffect(() => {
        setDefaultSetting({
            ...defaultSetting,
            paymentMethod: JSON.parse(JSON.stringify({ data: data.data })),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (skipFirstEffect)
            editPayment(
                franchiseID,
                paymentMethod,
                setIsAddPaymentClicked,
                setDataIsUpdated,
                dataIsUpdated
            );
        else setSkipFirstEffect(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPaymentModified]);
    React.useEffect(() => {
        setUrl(
            `${process.env.REACT_APP_API_V2_URL}/settings/defaultSetting/getData?type=paymentMethod&franchiseId=${franchiseID}`
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <h2 className="mt-3 ml-3">{t("Payment method")}</h2>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: isAddPaymentClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    canMovedCheckboxLeftOnExpand
                    url={url}
                    setData={setData}
                    canSelect
                    customSelect
                    canResize
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight={"450px"}
                    maxHeight={"450px"}
                    name="tva"
                />
                {isAddPaymentClicked ? (
                    <PaymentForm
                        setIsAddPaymentClicked={setIsAddPaymentClicked}
                        paymentMethod={rowData}
                        setIsDataUpdated={setDataIsUpdated}
                        isDataUpdated={dataIsUpdated}
                        data={data.data}
                        setDataIsUpdated={setDataIsUpdated}
                        setRowData={setRowData}
                        isAddPaymentClicked={isAddPaymentClicked}
                    />
                ) : null}
            </div>
        </div>
    );
}
