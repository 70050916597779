import { mutate } from "swr";

export function editCurrency(franchiseID: any, updatedRows: any, setDataIsUpdated: Function,
    dataIsUpdated: boolean | number, setIsAddDeviceClicked: Function) {
    const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/defaultSetting/data`;
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            franchiseId: franchiseID,
            types: ["currency"],
            data: updatedRows,
        }),
    };
    try {
        mutate(
            apiUrl,
            fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("error");
                    } else {
                        setDataIsUpdated(!dataIsUpdated);
                        setIsAddDeviceClicked(false);
                        return true
                    }
                })
        );
    } catch (e) {
    }
    return true

}