import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledTextInput,
    CheckboxIcon,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import { setDefaultSetting, store as localStore } from "../store";
import { setIsEdited } from "@store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";

import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { languages } from "@constants/index";
import {
    advancedDisplayNameDefault,
    getTag,
    translatedSaleSupport,
} from "@pages/SettingGeneral/SaleSupport/SaleSupportFranchise/helper";
import MultiCustomCreatableSelection from "@components/Common/MultiCustomCreatableSelection";
import { editSale } from "./helper";
import { updateSaleModesAndPaymentMethods } from "@helpers/general";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";

export default function SaleForm({
    setIsAddSaleSupportClicked,
    saleSupport,
    data,
    setDataIsUpdated,
    dataIsUpdated,
    isAddSaleSupportClicked,
    setRowData,
}: any) {
    const { t } = useTranslation();
    const { userID, franchiseID, shopID } = useSnapshot(store);
    const { defaultSetting } = useSnapshot(localStore);
    const [displayName, setDisplayName] = React.useState<string>("");
    const [saleSupportLabel, setSaleSupportLabel] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [tags, setTags] = React.useState<any[]>([]);
    const [generalSalesSupports, setGeneralSalesSupports] = React.useState<
        any[]
    >([]);
    const [tagsData, setTagsData] = React.useState<any>([]);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    const [advancedDisplayName, setAdvancedDisplayName] = React.useState<any>(
        advancedDisplayNameDefault
    );
    const selectedLanguage: any = languages.find(
        (el: any) => el.value === i18nextLng
    );

    const handleEdit = () => {
        if (
            saleSupport.advancedDisplayName.languages[i18nextLng] ===
                displayName &&
            tagsData.join(",") === saleSupport.Tag
        ) {
            setIsModalOpened(true);
        } else {
            toast.dismiss();
            const updatedRows = data.map((row: any) => {
                if (row.Id === saleSupport.Id) {
                    return {
                        ...row,
                        "Nom d'affichage": displayName,
                        Tag: tagsData.join(","),
                        status: displayName === "" ? false : row.status,
                        advancedDisplayName: {
                            ...row.advancedDisplayName,
                            languages: {
                                ...row.advancedDisplayName.languages,
                                [i18nextLng]: displayName,
                            },
                        },
                    };
                }
                return { ...row };
            });
            if (displayName === "") {
                const {
                    updatedSaleModes,
                    updatedPaymentMethod,
                } = updateSaleModesAndPaymentMethods(
                    saleSupport.status,
                    updatedRows,
                    defaultSetting
                );
                setDefaultSetting({
                    ...defaultSetting,
                    saleSupport: JSON.parse(
                        JSON.stringify({ data: updatedRows })
                    ),
                    saleMode: JSON.parse(
                        JSON.stringify({ data: updatedSaleModes })
                    ),
                    paymentMethod: JSON.parse(
                        JSON.stringify({ data: updatedPaymentMethod })
                    ),
                });
                editSale(
                    franchiseID,
                    updatedRows,
                    setIsAddSaleSupportClicked,
                    setDataIsUpdated,
                    dataIsUpdated,
                    updatedSaleModes,
                    updatedPaymentMethod
                );
            } else {
                setDefaultSetting({
                    ...defaultSetting,
                    saleSupport: JSON.parse(
                        JSON.stringify({ data: updatedRows })
                    ),
                });
                editSale(
                    franchiseID,
                    updatedRows,
                    setIsAddSaleSupportClicked,
                    setDataIsUpdated,
                    dataIsUpdated
                );
            }
            toast.success(
                `${t("The modification has been successfully completed")}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        }
    };
    async function getGeneralSaleSupport() {
        const newList: any[] = [];
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/generalList?type=saleSupport&userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}`,

            {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            }
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    for (let element of result.data) {
                        newList.push({
                            value: element.Id,
                            label: element.Designation,
                        });
                    }
                    setGeneralSalesSupports(newList);
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    const formatCreateLabelTag = (inputValue: any) => (
        <span>
            {inputValue} ({t("New tag")})
        </span>
    );

    useEffect(() => {
        clearData();
        setTitle(t("Modify a sale support"));
        setDisplayName(saleSupport.advancedDisplayName.languages[i18nextLng]);
        setTagsData(saleSupport.Tag.split(","));
        setSaleSupportLabel(t(saleSupport.support_de_vente));
        setAdvancedDisplayName(saleSupport.advancedDisplayName);

        // eslint-disable-next-line
    }, [saleSupport, t]);

    useEffect(() => {
        getGeneralSaleSupport();
        getTag(userID, shopID, franchiseID, setTags);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleSupport]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5 className="pt-3 sdv_txt_FRtitleFormSalesSupport">
                        {title}
                    </h5>
                    <CrossIcon
                        className="icon-close-notification__clz sdv_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsAddSaleSupportClicked(false);
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5 `}
                    style={{
                        maxHeight: "470px",
                        overflowY: " auto",
                    }}
                >
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Sales support")}
                        </StyledLabel>
                        <StyledSelectInput
                            isClearable
                            isDisabled={true}
                            options={generalSalesSupports}
                            autoComplete="off"
                            placeholder={`${t("Select")}…`}
                            value={
                                saleSupportLabel === ""
                                    ? null
                                    : {
                                          label: saleSupportLabel,
                                          value: saleSupportLabel,
                                      }
                            }
                            onChange={(e: any) => {
                                if (e === null && displayName === "") {
                                    setAdvancedDisplayName(
                                        advancedDisplayNameDefault
                                    );
                                    setSaleSupportLabel("");
                                } else if (e !== null) {
                                    setAdvancedDisplayName({
                                        ...advancedDisplayName,
                                        languages:
                                            translatedSaleSupport[e.label],
                                    });
                                    setSaleSupportLabel(e.label);
                                }
                                setDisplayName(e !== null ? e.label : "");
                            }}
                            className={classnames("sdv_inp_FRsalesSupport", {
                                "not-allowed-input__clz":
                                    saleSupport.id !== undefined &&
                                    generalSalesSupports.includes(
                                        saleSupport[t("Sale support")]
                                    ),
                            })}
                            noOptionsMessage={() => t("No options")}
                            name={t("Sales support")}
                            autoFocus
                        />
                    </AvGroup>
                    <AvGroup>
                        <div className="d-flex justify-content-between mb-1">
                            <StyledLabel htmlFor="text" className="mt-3">
                                {t("Display name")}
                            </StyledLabel>
                        </div>
                        <StyledTextInput
                            autoComplete="off"
                            name="DisplayName"
                            onChange={(e: any) => {
                                if (
                                    saleSupportLabel === "" &&
                                    e.target.value === ""
                                ) {
                                    setAdvancedDisplayName(
                                        advancedDisplayNameDefault
                                    );
                                } else {
                                    setAdvancedDisplayName({
                                        ...advancedDisplayName,
                                        languages: {
                                            ...advancedDisplayName.languages,
                                            [selectedLanguage.value]:
                                                e.target.value,
                                        },
                                    });
                                }
                                e !== null
                                    ? setDisplayName(e.target.value.trim())
                                    : setDisplayName("");
                            }}
                            value={
                                advancedDisplayName.languages[i18nextLng] ?? ""
                            }
                            placeholder={t("Write")}
                            type="text"
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Tag")}
                        </StyledLabel>
                        <MultiCustomCreatableSelection
                            onDelete={() => {}}
                            isSendingTag={false}
                            setResult={setTagsData}
                            result={tagsData}
                            data={tags}
                            onChange={(e: any) => {}}
                            onCreateOption={(e: any) => {}}
                            placeholder={`${t("Select")}…`}
                            value={t("Choose")}
                            name="tag"
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabelTag}
                            className="sdv_inp_FRtags"
                            isDefault={true}
                        />
                        <div className="d-flex flex-wrap"></div>
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 sdv_btn_FRcancel cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            setIsAddSaleSupportClicked(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        onClick={() => {
                            handleEdit();
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddSaleSupportClicked}
                setIsAddClicked={setIsAddSaleSupportClicked}
                setEditedData={setRowData}
                setIsEdited={setIsEdited}
            />
        </React.Fragment>
    );
}
