import { proxy } from "valtio";
import { defaultData } from "@constants/template";

interface MediaFile {
    Url: string;
    Name: string;
    Type: string;
    Extenxion: string;
}

interface PubItem {
    Pub: MediaFile;
    Rank: number;
    Redirection: string;
}

interface LanguageContent {
    AL: string;
    EN: string;
    ES: string;
    FR: string;
    IT: string;
}

interface SocialMedia {
    Url: string;
    Activer: boolean;
}

interface SocialMediaGroup {
    TikTok: SocialMedia;
    Youtube: SocialMedia;
    Facebook: SocialMedia;
    Snapchat: SocialMedia;
    Instagram: SocialMedia;
}

interface HeaderContent {
    Image: {
        Mobile: MediaFile;
        Desktop: MediaFile;
    };
    Title: LanguageContent;
    Description?: LanguageContent;
}

interface PopupContent {
    Image: {
        Mobile: MediaFile;
        Desktop: MediaFile;
    };
    PhoneNumber: string;
}

interface BoutiqueContent {
    Logo: {
        Mobile: MediaFile;
        Desktop: MediaFile;
    };
    Popup: PopupContent;
}

interface Section {
    Title: LanguageContent;
    Image1?: {
        Mobile: MediaFile;
        Desktop: MediaFile;
    };
    Image2?: {
        Mobile: MediaFile;
        Desktop: MediaFile;
    };
    Image3?: {
        Mobile: MediaFile;
        Desktop: MediaFile;
    };
    Images?: {
        Title: LanguageContent;
        Mobile: PubItem[];
        Desktop: PubItem[];
        Description: LanguageContent;
    };
    Color?: string;
    Image?: MediaFile;
    Description?: LanguageContent;
    Activer?: boolean;
    AppStore?: {
        Url: string;
        Activer: boolean;
    };
    PlayeStore?: {
        Url: string;
        Activer: boolean;
    };
}

interface LoyaltyContent {
    Head: {
        Bannier: MediaFile;
    };
    Solde: {
        Title: LanguageContent;
        Info_1: LanguageContent;
        Info_2: LanguageContent;
        BottomTitle: LanguageContent;
    };
    Avantages: {
        Image: MediaFile;
        Title: LanguageContent;
        Info_1: LanguageContent;
        Info_2: LanguageContent;
    };
    Recompenses: {
        Title: LanguageContent;
        BottomTitle: LanguageContent;
    };
}

interface LandingPageContent {
    Head: {
        Title: LanguageContent;
        Bannier: MediaFile;
    };
    Offres: {
        Pubs: PubItem[];
        Title: LanguageContent;
    };
    Loyalty: {
        Title1: LanguageContent;
        Title2: LanguageContent;
        Bannier: MediaFile;
    };
    BestProducts: {
        Title: LanguageContent;
        Products: any[]; // You might want to define a proper type for products
    };
}

interface ChoixBoutiqueContent {
    Description: LanguageContent;
    SocialMedia: SocialMediaGroup;
    ImageDetails: MediaFile;
    LogoBoutique: MediaFile;
    BannierBoutique: MediaFile;
}

interface ImagePublicite {
    Logo: MediaFile;
    Pubs: PubItem[];
}

interface Divers {
    Icone?: MediaFile;
    Langue: {
        AL: boolean;
        EN: boolean;
        ES: boolean;
        FR: boolean;
        IT: boolean;
        Faivorit: string;
    };
    ImagePopup?: {
        Mobile: MediaFile;
        Desktop: MediaFile;
    };
    SocialMedia?: SocialMediaGroup;
    CouleurTitre: string;
    TypeEcriture: string;
    ArrondiBouton: string;
    CouleurPrincipale: string;
}

interface PaymentContent {
    [key: string]: {
        Header: HeaderContent;
    };
}

interface ProfileContent {
    [key: string]: {
        Header: HeaderContent;
    };
}

interface PriseCommandeContent {
    [key: string]: {
        Header: HeaderContent;
    };
}

interface SiteWeb {
    Divers: Divers;
    Payment: PaymentContent;
    Profile: ProfileContent;
    LaindingPage: {
        Activer: boolean;
        Bannier: {
            Title: LanguageContent;
            Mobile: PubItem[];
            Desktop: PubItem[];
            Description: LanguageContent;
        };
        Section1: Section;
        Section2: Section;
        Section3: Section;
        Section4: Section;
    };
    ChoixBoutique: {
        Header: HeaderContent;
        Boutique: {
            [key: string]: BoutiqueContent;
        };
    };
    PriseCommande: PriseCommandeContent;
}

interface Application {
    Divers: Divers;
    Loyalty: {
        [key: string]: LoyaltyContent;
    };
    LaindingPage: {
        [key: string]: LandingPageContent;
    };
    ChoixBoutique: {
        [key: string]: ChoixBoutiqueContent;
    };
    ImagePublicite: ImagePublicite;
}

interface TemplateState {
    template: {
        SiteWeb: SiteWeb;
        Application: Application;
    };
    isLoading: boolean;
    DateModification?: string;
}

export const templateStore = proxy<TemplateState>({
    template: {
        SiteWeb: {
            Divers: {
                Langue: {
                    AL: false,
                    EN: false,
                    ES: false,
                    FR: true,
                    IT: false,
                    Faivorit: "FR",
                },
                CouleurTitre: "",
                TypeEcriture: "",
                ArrondiBouton: "",
                CouleurPrincipale: "",
            },
            Payment: {},
            Profile: {},
            LaindingPage: {
                Activer: false,
                Bannier: {
                    Title: { AL: "", EN: "", ES: "", FR: "", IT: "" },
                    Mobile: [],
                    Desktop: [],
                    Description: { AL: "", EN: "", ES: "", FR: "", IT: "" },
                },
                Section1: {
                    Title: { AL: "", EN: "", ES: "", FR: "", IT: "" },
                },
                Section2: {
                    Title: { AL: "", EN: "", ES: "", FR: "", IT: "" },
                },
                Section3: {
                    Title: { AL: "", EN: "", ES: "", FR: "", IT: "" },
                },
                Section4: {
                    Title: { AL: "", EN: "", ES: "", FR: "", IT: "" },
                },
            },
            ChoixBoutique: {
                Header: {
                    Image: {
                        Mobile: { Url: "", Name: "", Type: "", Extenxion: "" },
                        Desktop: { Url: "", Name: "", Type: "", Extenxion: "" },
                    },
                    Title: { AL: "", EN: "", ES: "", FR: "", IT: "" },
                },
                Boutique: {},
            },
            PriseCommande: {},
        },
        Application: {
            Divers: {
                Langue: {
                    AL: false,
                    EN: false,
                    ES: false,
                    FR: false,
                    IT: false,
                    Faivorit: "FR",
                },
                CouleurTitre: "",
                TypeEcriture: "",
                ArrondiBouton: "",
                CouleurPrincipale: "",
            },
            Loyalty: {},
            LaindingPage: {},
            ChoixBoutique: {},
            ImagePublicite: {
                Logo: { Url: "", Name: "", Type: "", Extenxion: "" },
                Pubs: [],
            },
        },
    },
    isLoading: true,
});

// Action functions to modify the state
export const templateActions = {
    setTemplate: (template: any) => {
        templateStore.template = template;
    },
    setLoading: (loading: boolean) => {
        templateStore.isLoading = loading;
    },
    updateTemplate: (path: string[], value: any) => {
        let current: any = templateStore;
        for (let i = 0; i < path.length - 1; i++) {
            current = current[path[i]];
            if (!current) return; // Path doesn't exist
        }
        current[path[path.length - 1]] = value;
    },
    // Specific actions for common operations
    updateLanguageContent: (
        path: string[],
        lang: keyof LanguageContent,
        value: string
    ) => {
        const target = templateActions.getNestedObject(path);
        if (target && target[lang] !== undefined) {
            target[lang] = value;
        }
    },
    resetLanguage: () => {
        templateActions.updateLanguageContent(
            ["template", "SiteWeb", "ChoixBoutique", "Header", "Title"],
            "FR",
            defaultData.Title.FR
        );
        templateActions.updateLanguageContent(
            ["template", "SiteWeb", "ChoixBoutique", "Header", "Title"],
            "EN",
            defaultData.Title.EN
        );
        templateActions.updateLanguageContent(
            ["template", "SiteWeb", "ChoixBoutique", "Header", "Title"],
            "ES",
            defaultData.Title.ES
        );
        templateActions.updateLanguageContent(
            ["template", "SiteWeb", "ChoixBoutique", "Header", "Title"],
            "IT",
            defaultData.Title.IT
        );
        templateActions.updateLanguageContent(
            ["template", "SiteWeb", "ChoixBoutique", "Header", "Title"],
            "AL",
            defaultData.Title.AL
        );

        templateActions.updateLanguageContent(
            ["template", "SiteWeb", "ChoixBoutique", "Header", "Description"],
            "FR",
            defaultData.Description.FR
        );
        templateActions.updateLanguageContent(
            ["template", "SiteWeb", "ChoixBoutique", "Header", "Description"],
            "EN",
            defaultData.Description.EN
        );
        templateActions.updateLanguageContent(
            ["template", "SiteWeb", "ChoixBoutique", "Header", "Description"],
            "ES",
            defaultData.Description.ES
        );
        templateActions.updateLanguageContent(
            ["template", "SiteWeb", "ChoixBoutique", "Header", "Description"],
            "IT",
            defaultData.Description.IT
        );
        templateActions.updateLanguageContent(
            ["template", "SiteWeb", "ChoixBoutique", "Header", "Description"],
            "AL",
            defaultData.Description.AL
        );
    },
    updateMediaFile: (
        path: string[],
        field: keyof MediaFile,
        value: string
    ) => {
        const target = templateActions.getNestedObject(path);
        if (target && target[field] !== undefined) {
            target[field] = value;
        }
    },
    toggleSocialMedia: (
        path: string[],
        platform: keyof SocialMediaGroup,
        active: boolean
    ) => {
        const target = templateActions.getNestedObject(path);
        if (
            target &&
            target[platform] &&
            target[platform].Activer !== undefined
        ) {
            target[platform].Activer = active;
        }
    },
    getNestedObject: (path: string[]): any => {
        let current: any = templateStore;
        for (const key of path) {
            if (current[key] === undefined) return null;
            current = current[key];
        }
        return current;
    },
};
