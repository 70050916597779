import React from "react";
import { Tooltip } from "@mui/material";
import ReactSwitch from "react-switch";
import { CheckboxIcon, HeartIcon, PencilIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";

import { setDefaultSetting, store } from "../store";
import { store as globalStore } from "@components/VerticalLayout/store";

import { editSale } from "./helper";

export function CustomIcons({
    e,
    setIsAddSaleClicked,
    setRowData,
    setIsAddSaleSupportClicked,
    setDataIsUpdated,
    dataIsUpdated,
}: any): JSX.Element {
    const { t } = useTranslation();
    const { franchiseID } = useSnapshot(globalStore);

    const { defaultSetting } = useSnapshot(store);

    const handleHeartClick = () => {
        const updatedRows = defaultSetting.saleMode?.data?.map((row: any) => {
            if (row.Id === e.selectedRow.Id) {
                return { ...row, isFavorite: true };
            }
            return { ...row, isFavorite: false };
        });
        editSale(
            franchiseID,
            updatedRows,
            setIsAddSaleSupportClicked,
            setDataIsUpdated,
            dataIsUpdated
        );
        toast.success(`${t("Favorite state has been enabled successfully")}`, {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            icon: <CheckboxIcon height={25} width={25} fill="white" />,
        });
    };
    const handlePencilClick = () => {
        setRowData(e.selectedRow);
        setIsAddSaleClicked(true);
    };

    const handleSwitchChange = async (isChecked: boolean) => {
        const updatedSaleMode = defaultSetting.saleMode?.data?.map(
            (row: any) => {
                if (row.Id === e.selectedRow.Id) {
                    return { ...row, status: isChecked };
                }
                return row;
            }
        );
        editSale(
            franchiseID,
            updatedSaleMode,
            setIsAddSaleSupportClicked,
            setDataIsUpdated,
            dataIsUpdated
        );
        toast.success(
            `${t(
                isChecked
                    ? "Sales method has been successfully activated"
                    : "Sales method has been successfully deactivated"
            )}`,
            {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            }
        );
        setDefaultSetting({
            ...defaultSetting,
            saleMode: JSON.parse(JSON.stringify({ data: updatedSaleMode })),
        });
    };
    const filteredSaleSupport: any = (
        defaultSetting?.saleSupport?.data ?? []
    ).filter((item: any) => item.status === true);
    const supportedSaleModes = filteredSaleSupport.map(
        (support: any) => support.support_de_vente
    );
    const supportVente = e.selectedRow.support_vente || [];
    const isSupported = supportVente.some((mode: any) =>
        supportedSaleModes.includes(mode)
    );

    return (
        <div className="d-flex justify-content-around">
            <Tooltip
                title={
                    !isSupported
                        ? ""
                        : e.selectedRow.status === false
                        ? t("Activate")
                        : t("Unactivate")
                }
                arrow
            >
                <div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                        checked={e.selectedRow.status}
                        onChange={handleSwitchChange}
                        disabled={!isSupported}
                    />
                </div>
            </Tooltip>
            <div className="ml-3">
                <Tooltip
                    title={
                        e.selectedRow.isFavorite === true
                            ? ""
                            : t("Enable favorite state")
                    }
                    arrow
                >
                    <div>
                        <HeartIcon
                            onClick={handleHeartClick}
                            height={25}
                            width={25}
                            name="heart"
                            fill={e.selectedRow.isFavorite ? "red" : "black"}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                </Tooltip>
            </div>
            <div className="ml-3">
                <Tooltip title={t("To modify")} arrow>
                    <div>
                        <PencilIcon
                            onClick={handlePencilClick}
                            height={25}
                            width={25}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}
