import React from "react";
import { Card, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { useSnapshot } from "valtio";
import {
    CheckIcon,
    PencilIcon,
    StyledIconButton,
    RefreshIcon,
} from "@aureskonnect/react-ui";
import { uid } from "react-uid";

import {
    generalConfigStore,
    setProjectFiles,
    setIsInformationModesSkipped,
    setIsResetOneImageButtonClicked,
    store,
} from "@store";
import { store as storeGlobal } from "@components/VerticalLayout/store";
import { getImageContentById } from "@helpers/general";

import { Uploader } from "@components/Uploader";
import classnames from "classnames";

type PropsType = {
    item: ProjectMainContentItemType;
    index: number;
    setItems: React.Dispatch<
        React.SetStateAction<ProjectMainContentItemType[]>
    >;
    setConfig: (items: ProjectMainContentItemType[]) => void;
    hasTooltip: boolean;
};

export function InformationModesCardWrapper({
    item,
    index,
    setItems,
    setConfig,
    hasTooltip = false,
}: PropsType): JSX.Element {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);
    const {
        defaultLanguage,
        selectedSaleModeValue,
        isResetOneImageButtonClicked,
        project,
        imageKey,
        itemsDisable,
    } = useSnapshot(generalConfigStore);
    const { shopID, oneShop } = useSnapshot(storeGlobal);

    const [cardImageKey, setCardImageKey] = React.useState<string>("");
    const [inputValue, setInputValue] = React.useState<string>(
        item.languages[defaultLanguage.name].name
    );
    const shop_id = oneShop
        ? shopID
        : project.shopId !== undefined
        ? project.shopId
        : shopID;
    const imageContent = getImageContentById(
        item.id,
        project.files,
        defaultLanguage.name
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleInputOnBlurEvent() {
        setItems(
            (
                prevState: ProjectMainContentItemType[]
            ): ProjectMainContentItemType[] => {
                let newState = [...prevState];

                newState = newState.map(
                    (item: ProjectMainContentItemType, itemIndex: number) => {
                        if (itemIndex === index) {
                            return {
                                ...item,
                                languages: {
                                    ...item.languages,
                                    [defaultLanguage.name]: {
                                        ...item.languages[defaultLanguage.name],
                                        name: inputValue,
                                    },
                                },
                            };
                        }

                        return { ...item };
                    }
                );
                setConfig(newState);
                return newState;
            }
        );
    }

    function handleCheckIconOnClickEvent(index: number) {
        setItems((prevState: ProjectMainContentItemType[]) => {
            let newState = [...prevState];

            newState = newState.map(
                (item: ProjectMainContentItemType, itemIndex: number) => {
                    if (itemIndex === index) {
                        return {
                            ...item,
                            languages: {
                                ...item.languages,
                                [defaultLanguage.name]: {
                                    ...item.languages[defaultLanguage.name],
                                    name: inputValue,
                                },
                            },
                            active: {
                                ...(item.active as ItemContentType),
                                [selectedSaleModeValue]: !(item.active as ItemContentType)[
                                    selectedSaleModeValue
                                ],
                            },
                        };
                    }

                    return { ...item };
                }
            );

            if (
                newState.filter((item) => {
                    return (
                        (item.active as ItemContentType)[
                            selectedSaleModeValue
                        ] === true
                    );
                }).length === 0
            ) {
                setIsInformationModesSkipped(true, selectedSaleModeValue);
            }

            setConfig(newState);
            return newState;
        });
    }

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...project.files];
        const image = imagesArray.find((element) => element.id === id);

        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            const localContent = image.content as ImageItemLanguagesType;

            imagesArray[imageIndex] = {
                ...image,
                content: {
                    ...localContent,
                    [defaultLanguage.name]: {
                        ...localContent[defaultLanguage.name],
                        path: localContent[defaultLanguage.name]
                            .defaultImage as string,
                    },
                } as ImageItemLanguagesType,
            };
        }
        setProjectFiles(imagesArray);
        setCardImageKey(uid(imagesArray));
    }

    React.useEffect(() => {
        setInputValue(item.languages[defaultLanguage.name].name);
    }, [defaultLanguage, item.languages]);
    return (
        <div
            className="d-flex flex-column justify-content-center cmn_chk_selectButton"
            style={{
                gap: "10px",
                width: "min-content",
                borderBottom: "1px solid #707070",
                opacity:
                    (item.active as ItemContentType)[selectedSaleModeValue] ===
                        false ||
                    itemsDisable.find(
                        (element: any) =>
                            element.id === item.id && element.shopId === shop_id
                    ) !== undefined
                        ? "40%"
                        : "",
            }}
        >
            <div
                className="d-flex align-items-center justify-content-start align-items-center text-muted"
                style={{ gap: "10px" }}
            >
                {/* {hasTooltip === true ? (
                    <div>
                        <div id="tooltip-title-card">
                            <HelpIcon height={25} width={25} />
                        </div>
                        <UncontrolledTooltip
                            placement="bottom"
                            target="tooltip-title-card"
                        >
                            {t("You can edit it")}
                        </UncontrolledTooltip>
                    </div>
                ) : null} */}
                {t(item.name)}
            </div>
            <Card
                className={classnames({
                    "disable-account-creation-items__clz":
                        itemsDisable.find(
                            (element: any) =>
                                element.id === item.id &&
                                element.shopId === `${shop_id}`
                        ) !== undefined,
                })}
                style={{
                    borderBottom:
                        (item.active as ItemContentType)[
                            selectedSaleModeValue
                        ] === true
                            ? `${mainColor} 10px solid`
                            : undefined,
                    width: "245px",
                    height: "205px",
                    borderRadius: "20px",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateRows: "0.9fr auto",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "0.2fr 1fr 0.1fr",
                            height: "100%",
                        }}
                    >
                        <CheckIcon
                            onClick={() => {
                                if (
                                    itemsDisable.find(
                                        (element: any) =>
                                            element.id === item.id &&
                                            element.shopId === `${shop_id}`
                                    ) === undefined
                                ) {
                                    handleCheckIconOnClickEvent(index);
                                }
                            }}
                            className="mt-1 ml-1"
                            height={45}
                            width={45}
                            style={{
                                fill:
                                    (item.active as ItemContentType)[
                                        selectedSaleModeValue
                                    ] === true
                                        ? mainColor
                                        : "#BCBCBC",
                                cursor: "pointer",
                            }}
                        />
                        <div
                            className="d-flex justify-content-center align-items-end"
                            onClick={() => {
                                if (
                                    itemsDisable.find(
                                        (element: any) =>
                                            element.id === item.id &&
                                            element.shopId === `${shop_id}`
                                    ) === undefined
                                ) {
                                    handleCheckIconOnClickEvent(index);
                                }
                            }}
                        >
                            <img
                                alt="img"
                                key={index}
                                src={imageSrc}
                                style={{
                                    width: "90px",
                                    height: "90px",
                                }}
                            />
                        </div>
                        <StyledIconButton
                            title={t("Reset to default image")}
                            disabled={item.active === false}
                            outline={true}
                            variant="danger"
                            className="m-0 p-0"
                            icon="RefreshIcon"
                            style={{ height: "20%" }}
                            onClick={() => {
                                handleResetImagesOnClickEvent(item.id);
                                setIsResetOneImageButtonClicked(true);
                            }}
                        >
                            <RefreshIcon height={20} width={20} />
                        </StyledIconButton>
                    </div>

                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            gap: "10px",
                        }}
                    >
                        <div
                            className="d-flex justify-content-center shadow-sm align-items-center rounded border"
                            style={{
                                height: "40px",
                                width: "40px",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "15px",
                                }}
                            >
                                {index + 1}
                                <sup>
                                    {index + 1 === 1 ? t("First") : t("th")}
                                </sup>
                            </span>
                        </div>

                        <fieldset
                            disabled={
                                (item.active as ItemContentType)[
                                    selectedSaleModeValue
                                ] === false ||
                                itemsDisable.find(
                                    (element: any) =>
                                        element.id === item.id &&
                                        element.shopId === shop_id
                                ) !== undefined
                            }
                        >
                            <div
                                className="d-flex justify-content-center shadow-sm align-items-center rounded border cmn_icn_uploadPhoto"
                                style={{
                                    height: "40px",
                                    width: "40px",
                                }}
                            >
                                <Uploader
                                    key={
                                        isResetOneImageButtonClicked
                                            ? cardImageKey
                                            : imageKey
                                    }
                                    id={item.id}
                                    activeLanguage={defaultLanguage.name}
                                />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </Card>
            <AvForm
                className="d-flex justify-content-center align-items-center"
                style={{ gap: "5px" }}
            >
                {(item.active as ItemContentType)[selectedSaleModeValue] ===
                true ? (
                    <PencilIcon height={20} width={20} />
                ) : null}

                <Input
                    className="border-0"
                    id="card-title"
                    name="card-title"
                    type="text"
                    disabled={
                        (item.active as ItemContentType)[
                            selectedSaleModeValue
                        ] === false ||
                        itemsDisable.find(
                            (element: any) =>
                                element.id === item.id &&
                                element.shopId === shop_id
                        ) !== undefined
                    }
                    style={{
                        backgroundColor: "transparent",
                        fontSize: "20px",
                        color: "#000000",
                    }}
                    value={inputValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (
                            (item.active as ItemContentType)[
                                selectedSaleModeValue
                            ]
                        ) {
                            setInputValue(event.target.value);
                        }
                    }}
                    onBlur={handleInputOnBlurEvent}
                />
            </AvForm>
        </div>
    );
}
