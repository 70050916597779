import { mutate } from "swr";

export function editPayment(franchiseID: any, updatedRows: any, setIsAddPaymentClicked: Function,
    setDataIsUpdated: Function, dataIsUpdated: boolean | number) {
    const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/defaultSetting/data`;
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            franchiseId: franchiseID,
            types: ["paymentMethod"],
            data: updatedRows,
        }),
    };
    try {
        mutate(
            apiUrl,
            fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("error");
                    } else {
                        setDataIsUpdated(!dataIsUpdated);
                        setIsAddPaymentClicked(false);

                    }
                })
        );
    } catch (e) { }
};