import React, { Fragment, useEffect, useState } from "react";
import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { setDefaultSetting, store } from "../store";
import { store as globalStore } from "@components/VerticalLayout/store";

import { CustomIcons } from "./CustomIcons";
import { DisplayName } from "./DisplayName";
import { LanguageForm } from "./LanguageForm";

export function Language() {
    const { t } = useTranslation();
    const { defaultSetting } = useSnapshot(store);
    const { franchiseID } = useSnapshot(globalStore);

    const [filterActive, setLocalFilterActive] = useState<boolean>(false);
    const [isAddLanguageClicked, setIsAddLanguageClicked] = useState<boolean>(
        false
    );
    const [dataIsUpdated, setDataIsUpdated] = useState<any>();
    const [data, setData] = useState<any>({});
    const [rowData, setRowData] = useState<any>({});
    const [url, setUrl] = useState<any>("/DefaultSetting/language.json");

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 1,
            columnName: t("Language"),
            customJsx: (e: any) => (
                <Fragment>{t(e.selectedRow["language"])}</Fragment>
            ),
        },
        {
            indexOFColumn: 2,
            columnName: t("Display name"),
            customJsx: (e: any) => <DisplayName e={e} />,
            filterName: "display_name",
            canFilter: true,
        },
        {
            indexOFColumn: 3,
            columnName: t("Country"),
            customJsx: (e: any) => (
                <Fragment> {t(e.selectedRow["Pays"])}</Fragment>
            ),
        },
        {
            indexOFColumn: 4,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    setIsAddLanguageClicked={setIsAddLanguageClicked}
                    setRowData={setRowData}
                />
            ),
            disableFilter: false,
        },
    ];
    useEffect(() => {
        setDefaultSetting({
            ...defaultSetting,
            language: JSON.parse(JSON.stringify({ data: data.data })),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    React.useEffect(() => {
        setUrl(
            `${process.env.REACT_APP_API_V2_URL}/settings/defaultSetting/getData?type=language&franchiseId=${franchiseID}`
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <h2 className="mt-3 ml-3">{t("Language")}</h2>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: isAddLanguageClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    canMovedCheckboxLeftOnExpand
                    url={url}
                    setData={setData}
                    canSelect
                    customSelect
                    canResize
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight={"450px"}
                    maxHeight={"450px"}
                    name="tva"
                />
                {isAddLanguageClicked ? (
                    <LanguageForm
                        setIsAddLanguageClicked={setIsAddLanguageClicked}
                        setDataIsUpdated={setDataIsUpdated}
                        rowData={rowData}
                        dataIsUpdated={dataIsUpdated}
                        isAddLanguageClicked={isAddLanguageClicked}
                        setRowData={setRowData}
                    />
                ) : null}
            </div>
        </div>
    );
}
