import { mutate } from "swr";
import { setIsPaymentModified, setIsSaleModeModified, setPayment, setSaleMode } from "../store";

export function editSale(franchiseID: any, updatedRows: any, setIsAddSaleSupportClicked: Function,
    setDataIsUpdated: Function, dataIsUpdated: boolean | number, dataSaleMode?: any, dataPayment?: any) {
    const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/defaultSetting/data`;
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            franchiseId: franchiseID,
            types: ["saleSupport"],
            data: updatedRows,
        }),
    };
    try {
        mutate(
            apiUrl,
            fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("error");
                    } else {
                        setDataIsUpdated(!dataIsUpdated);
                        setIsAddSaleSupportClicked(false);
                        if (dataSaleMode !== undefined) {
                            setSaleMode(dataSaleMode);
                            setIsSaleModeModified()
                        }
                        if (dataPayment !== undefined) {
                            setPayment(dataPayment);
                            setIsPaymentModified()
                        }

                    }
                })
        );
    } catch (e) { }
};