import React from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { useSnapshot } from "valtio";
import { StyledBreadcrumb, Tabs } from "@aureskonnect/react-ui";
import { mutate } from "swr";

import {
    setNumberClick,
    store,
} from "../../../components/VerticalLayout/store";
import { setDefaultSetting } from "@components/Common/DefaultSettingModal/ShopDefaultSetting/store";

import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import CustomerAccounts from "./CustomerAccounts";
import CustomerAccountShop from "./CustomerAccountShop";
import { MainColorTabsWrapper } from "@components/Common/MainColorTabsWrapper";
import { NProgressWrapper } from "@components/NProgressWrapper";
import { DefaultModalWrapper } from "@components/Common/DefaultSettingModal/DefaultModalWrapper";
import { DefaultModalContentWrapper } from "@components/Common/DefaultSettingModal/DefaultModalContentWrapper";
import { DefaultSettingModal } from "@components/Common/DefaultSettingModal/ShopDefaultSetting";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export default function VatRate() {
    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const { oneShop, franchiseID, numberClick, shopID } = useSnapshot(store);

    const [isDefaultSetting, setIsDefaultSetting] = React.useState<boolean>(
        false
    );
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [limit, setLimit] = React.useState<number>(1);

    const tabs1 = <CustomerAccountShop franchise={false} />;

    React.useEffect(() => {
        // eslint-disable-next-line
        fetch(
            `${
                process.env.REACT_APP_API_V2_URL
            }/settings/list/setting?franchiseId=${franchiseID}&shopId=${
                oneShop ? shopID : 0
            }`,
            {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            }
        )
            .then((value) => value.json())
            .then((result) => {
                setIsDefaultSetting(result.settingEmpty);
                setIsOpen(result.emptyShop);
                setDefaultSetting(result.defaultSetting);
                if (result.settingEmpty && numberClick === 0) {
                    const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/defaultSetting/data`;
                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            franchiseId: franchiseID,
                            types: [],
                            data: {},
                        }),
                    };
                    try {
                        mutate(
                            apiUrl,
                            fetch(apiUrl, requestOptions)
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.error) {
                                        throw Error("error");
                                    }
                                })
                        );
                    } catch (e) {}
                }
            });

        // eslint-disable-next-line
    }, []);
    const tabs2 = (
        <Tabs
            tabs={[
                {
                    content: <CustomerAccountShop franchise={true} />,
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark cmn_btn_boutique"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Customer Account")}
                        </div>
                    ),
                },
                {
                    content: <CustomerAccounts />,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark border-0 cmn_btn_franchise"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Shops Customer Account")}
                        </div>
                    ),
                },
            ]}
        />
    );
    return (
        <React.Fragment>
            <ToastContainer limit={limit} />
            <PageTitle title={t("General settings")} />
            <div className="page-content__clz">
                <StyledBreadcrumb
                    items={[
                        {
                            item: "Settings".toUpperCase(),
                            link: `${process.env.REACT_APP_PORTAIL_URL}`,
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: oneShop
                                ? "/setting/customer-account"
                                : "/compte",
                        },
                        {
                            item: t("General settings").toUpperCase(),
                            link: "#",
                        },
                        {
                            item: t(
                                "Customer account informations"
                            ).toUpperCase(),
                            link: "#",
                        },
                    ]}
                />
                <MessageInfo
                    message={t(
                        "The legal information related to an account can be accessed in this section."
                    )}
                />
                <MainColorTabsWrapper>
                    {oneShop ? tabs1 : tabs2}
                </MainColorTabsWrapper>
            </div>
            {isDefaultSetting && numberClick === 0 ? (
                <DefaultModalWrapper
                    isConfigurationModalOpened={isDefaultSetting}
                    setIsConfigurationModalOpened={setIsDefaultSetting}
                    setIsLoading={setIsLoading}
                    numberClick={numberClick}
                    setNumberClick={setNumberClick}
                    setLimit={setLimit}
                >
                    <DefaultModalContentWrapper />
                </DefaultModalWrapper>
            ) : null}
            {isOpen && numberClick === 0 ? (
                <DefaultSettingModal
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    setIsLoading={setIsLoading}
                    setNumberClick={setNumberClick}
                />
            ) : null}

            <NProgressWrapper isLoading={isLoading} />
        </React.Fragment>
    );
}
