import React from "react";
import { useTranslation } from "react-i18next";

import { NoPicture } from "@components/Common/SvgIcons/NoPicture";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";

type DisplayNameType = {
    e: any;
};

export function DisplayName({ e }: DisplayNameType) {
    const { t } = useTranslation();
    const url = e.selectedRow.url_image;

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <CustomColumnDisplay
                    text={
                        <React.Fragment>
                            <div className="d-flex align-items-center justify-content-center">
                                {e.selectedRow.country === "" ? (
                                    <NoPicture
                                        height="35px"
                                        width="40px"
                                        className="flag__clz"
                                    />
                                ) : (
                                    <img
                                        alt="nopicture.png"
                                        src={url}
                                        height="35px"
                                        width="40px"
                                        className="flag__clz"
                                    />
                                )}
                                {"    "}
                                {t(e.selectedRow.display_name)}
                            </div>{" "}
                        </React.Fragment>
                    }
                />
            ) : null}
        </React.Fragment>
    );
}
