import React from "react";
import { useTranslation } from "react-i18next";

import { useSnapshot } from "valtio";
import { store as defaultSettingStore } from "./store";

export function ConfigElement() {
    const { t } = useTranslation();
    const { defaultSetting } = useSnapshot(defaultSettingStore);

    return (
        <div>
            {defaultSetting.language.length > 0 && (
                <p>
                    <strong>{t("Language")} : </strong>
                    {defaultSetting.language.join(",")}
                </p>
            )}
            {defaultSetting.vatRateData.length > 0 && (
                <p>
                    <strong>{t("VAT")} : </strong>
                    {defaultSetting.vatRateData.join(",")}
                </p>
            )}

            {defaultSetting.saleSupport.length > 0 && (
                <p>
                    <strong>{t("Sale Support")} : </strong>
                    {defaultSetting.saleSupport.join(", ")}
                </p>
            )}
            {defaultSetting.saleMode.length > 0 && (
                <p>
                    <strong>{t("Sales method")}: </strong>
                    {defaultSetting.saleMode.join(", ")}
                </p>
            )}
            {defaultSetting.paymentMethodData.length > 0 && (
                <p>
                    <strong>{t("Payment method")} : </strong>
                    {defaultSetting.paymentMethodData.join(", ")}
                </p>
            )}
            {defaultSetting.deviceData.length > 0 && (
                <p>
                    <strong>{t("Currency")} : </strong>
                    {defaultSetting.deviceData.join(", ")}
                </p>
            )}
        </div>
    );
}
