import { StyledSelectInput } from "@aureskonnect/react-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { ImCross } from "react-icons/im";
import classnames from "classnames";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useSnapshot } from "valtio";
import { CheckboxIcon } from "@aureskonnect/react-ui";

import {
    setSelectedData,
    store,
    setRemoveSelectedData,
    clearMultiCustomSelectionData,
} from "./store";
import ErrorToast from "../ErrorTost";

export const ValuesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;
export const Value = styled.div`
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    margin: 5px;
    font-size: 0.75rem;
    color: black;
    background-color: hsl(0, 0%, 90%);
    user-select: none;
    border-radius: 0.3rem;
`;

export const CustomSelectInput = styled.div`
    position: relative;
`;

export const ClearButton = styled.button`
    position: absolute;
    right: 48px;
    border: none;
    top: 2px;
    height: 30px;
    cursor: pointer;
    background-color: white;
    transform: translateX(2px);
    opacity: 0.3;
    svg {
        width: 10px;
    }
`;

export const XButton = styled.button`
    all: unset;
    margin-left: 0.3rem;
    color: black;
    transition: fill 0.15s ease-in-out;
    cursor: pointer;
    &:hover {
        color: #bb392d;
    }
    &:focus {
        color: #c82f21;
    }
`;

type MultiCustomSelectType = {
    onChange: Function;
    className: any;
    placeholder: any;
    value: any;
    data: any;
    setResult: Function;
    name: string;
    result: any[];
    onDelete?: Function;
    blocked?: boolean;
    blockedList?: any;
    usedSaleSupports?: any;
    isModeOfSaleActiveInTemplate?: boolean;
    isDeliverooAvaible?: any;
    hideCross?: boolean;
};

export default function MultiCustomSelect({
    onChange,
    className,
    placeholder,
    value,
    data,
    setResult,
    name,
    result,
    onDelete,
    blocked,
    blockedList,
    usedSaleSupports,
    isModeOfSaleActiveInTemplate,
    isDeliverooAvaible,
    hideCross,
}: MultiCustomSelectType) {
    const { t } = useTranslation();
    const { selectedData } = useSnapshot(store);

    const [filteredData, setFilteredData] = React.useState<any>([]);

    const handleXButton = (e: any) => {
        setRemoveSelectedData(e, name);
        if (onDelete !== undefined) {
            onDelete!();
        }
    };

    React.useEffect(() => {
        let filteredResult: any = result.filter((el: any) => {
            return el !== "";
        });
        if (selectedData[name] === undefined) {
            filteredResult.forEach((el: any) => {
                setSelectedData(el, name);
            });
        }

        let localData = data.filter(
            (item: any) => !filteredResult.includes(item)
        );

        setFilteredData(
            localData.map((el: any) => {
                return { label: el, value: el };
            })
        );
    }, [data, result, name, selectedData]);

    React.useEffect(() => {
        setResult(selectedData[name] !== undefined ? selectedData[name] : []);
    }, [setResult, selectedData, name]);

    return (
        <React.Fragment>
            <CustomSelectInput>
                <StyledSelectInput
                    placeholder={placeholder}
                    value={value}
                    isClearable
                    className={classnames(
                        `${className}`,

                        { "not-allowed-input__clz": blocked === true }
                    )}
                    options={filteredData}
                    autocomplete="off"
                    name={name}
                    onChange={(e: any) => {
                        if (e !== null) {
                            if (
                                isDeliverooAvaible !== undefined &&
                                isDeliverooAvaible.active &&
                                e.value === "Deliveroo"
                            ) {
                                toast.warning(
                                    `${t(
                                        `Attention: Deliveroo is already linked to the '{{isDeliverooUsed}}' sales mode for the franchise and cannot be associated with another sales mode.`,
                                        {
                                            isDeliverooUsed:
                                                isDeliverooAvaible.saleMode,
                                        }
                                    )!}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                            } else {
                                setSelectedData(e.value, name);
                                onChange(e);
                            }
                        }
                    }}
                    type="text"
                    noOptionsMessage={() => t("No options")}
                    maxMenuHeight={"17vh"}
                />

                {result.length > 0 && blocked !== true && hideCross !== true ? (
                    <ClearButton
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            if (
                                usedSaleSupports !== undefined &&
                                usedSaleSupports.length > 0 &&
                                result
                                    .filter((el: any) => {
                                        return el !== "";
                                    })
                                    .some(
                                        (el: any) =>
                                            usedSaleSupports.includes(el) ||
                                            (isModeOfSaleActiveInTemplate ===
                                                true &&
                                                el === "KIOSK")
                                    )
                            ) {
                                ErrorToast(
                                    t(
                                        "Attention!, you cannot delete used sales support."
                                    )
                                );
                            } else {
                                setResult([]);
                                clearMultiCustomSelectionData();
                            }
                        }}
                    >
                        <ImCross height={6} width={6} />
                    </ClearButton>
                ) : null}
            </CustomSelectInput>

            <div className="d-flex flex-wrap  cmn_txt_selectedBox">
                {result

                    .filter((el: any) => {
                        return el !== "";
                    })

                    .map((el: any, index: number) => {
                        return (
                            <ValuesContainer key={index}>
                                <Value
                                    key={index}
                                    className={classnames(
                                        `${className}`,

                                        {
                                            "not-allowed-input__clz":
                                                blockedList !== undefined &&
                                                blockedList.length > 0 &&
                                                blockedList.includes(el),
                                        }
                                    )}
                                >
                                    {el}

                                    {blockedList !== undefined &&
                                    blockedList.length > 0 &&
                                    blockedList.includes(el) ? (
                                        <span></span>
                                    ) : blocked !== true ? (
                                        <XButton
                                            className="cmn_icn_selectedBox"
                                            key={index}
                                            onClick={(
                                                e: React.MouseEvent<HTMLElement>
                                            ) => {
                                                if (
                                                    (usedSaleSupports !==
                                                        undefined &&
                                                        usedSaleSupports.length >
                                                            0 &&
                                                        usedSaleSupports.includes(
                                                            el
                                                        )) ||
                                                    (isModeOfSaleActiveInTemplate ===
                                                        true &&
                                                        el === "KIOSK")
                                                ) {
                                                    ErrorToast(
                                                        t(
                                                            "Attention!, you cannot delete used sales support."
                                                        )
                                                    );
                                                } else {
                                                    handleXButton(el);
                                                }
                                            }}
                                        >
                                            ✕
                                        </XButton>
                                    ) : null}
                                </Value>
                            </ValuesContainer>
                        );
                    })}
            </div>
        </React.Fragment>
    );
}
