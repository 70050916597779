import React from "react";
import { Tooltip } from "@mui/material";
import ReactSwitch from "react-switch";
import { CheckboxIcon, PencilIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";

import { setDefaultSetting, store } from "../store";
import { store as globalStore } from "@components/VerticalLayout/store";

import { editPayment } from "./helper";

export function CustomIcons({
    e,
    setRowData,
    setIsAddPaymentClicked,
    setDataIsUpdated,
    dataIsUpdated,
}: any): JSX.Element {
    const { t } = useTranslation();
    const { franchiseID } = useSnapshot(globalStore);
    const { defaultSetting } = useSnapshot(store);
    const [isSupported, setIsSupported] = React.useState(false);
    const handlePencilClick = () => {
        setRowData(e.selectedRow);
        setIsAddPaymentClicked(true);
    };

    const handleSwitchChange = async (isChecked: boolean) => {
        const updatedPayment = defaultSetting.paymentMethod?.data?.map(
            (row: any) => {
                if (row.Id === e.selectedRow.Id) {
                    return { ...row, status: isChecked };
                }
                return row;
            }
        );
        editPayment(
            franchiseID,
            updatedPayment,
            setIsAddPaymentClicked,
            setDataIsUpdated,
            dataIsUpdated
        );
        toast.success(
            `${t(
                isChecked
                    ? "Payment method has been successfully activated"
                    : "Payment method has been successfully deactivated"
            )}`,
            {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            }
        );
        setDefaultSetting({
            ...defaultSetting,
            paymentMethod: JSON.parse(JSON.stringify({ data: updatedPayment })),
        });
    };

    React.useEffect(() => {
        const filteredSaleSupport: any = (
            defaultSetting?.saleSupport?.data ?? []
        ).filter((item: any) => item.status === true);
        const supportedSaleModes = filteredSaleSupport.map(
            (support: any) => support.support_de_vente
        );
        const supportVente = e.selectedRow.support_vente || [];
        const isSupported = supportVente.some((mode: any) =>
            supportedSaleModes.includes(mode)
        );
        setIsSupported(isSupported);
    }, [defaultSetting, e.selectedRow.support_vente]);
    return (
        <div className="d-flex justify-content-around">
            <Tooltip
                title={
                    !isSupported
                        ? ""
                        : e.selectedRow.status === false
                        ? t("Activate")
                        : t("Unactivate")
                }
                arrow
            >
                <div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                        checked={e.selectedRow.status}
                        onChange={() =>
                            handleSwitchChange(!e.selectedRow.status)
                        }
                        disabled={!isSupported}
                    />
                </div>
            </Tooltip>

            <div className="ml-3">
                <Tooltip title={t("To modify")} arrow>
                    <div>
                        <PencilIcon
                            onClick={handlePencilClick}
                            height={25}
                            width={25}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}
