import React, { useEffect, useState } from "react";
import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";

import { setDefaultSetting, store } from "../store";
import { store as globalStore } from "@components/VerticalLayout/store";

import { CustomIcons } from "./CustomIcons";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { CustomSaleSupportIcons } from "./CustomSaleSupportIcons";
import SaleForm from "./SaleForm";
import { editSale } from "./helper";

export function SaleMode() {
    const { t } = useTranslation();
    const { franchiseID } = useSnapshot(globalStore);

    const [filterActive, setLocalFilterActive] = useState<boolean>(false);
    const [isAddSaleClicked, setIsAddSaleClicked] = useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = useState<boolean | number>(false);
    const [data, setData] = useState<any>({});
    const [rowData, setRowData] = useState<any>({});
    const [skipFirstEffect, setSkipFirstEffect] = useState(false);
    const { defaultSetting, isSaleModeModified, saleMode } = useSnapshot(store);
    const [url, setUrl] = useState<any>("/DefaultSetting/saleMode.json");

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: t("Display name"),
            customJsx: (e: any) => (
                <CustomColumnDisplay text={t(e.selectedRow.display_name)} />
            ),
            filterName: "display_name",
            canFilter: true,
        },
        {
            indexOFColumn: 1,
            columnName: t("Sales method"),
            customJsx: (e: any) => (
                <span>{t(e.selectedRow["Mode of sale"])}</span>
            ),
            filterName: t("Sales method"),
            canFilter: true,
        },
        {
            indexOFColumn: 2,
            columnName: t("Sale support"),
            customJsx: (e: any) => <CustomSaleSupportIcons e={e} />,
            filterName: "support_vente",
            canFilter: true,
        },

        {
            indexOFColumn: 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setIsAddSaleClicked={setIsAddSaleClicked}
                    setRowData={setRowData}
                    setIsAddSaleSupportClicked={setIsAddSaleClicked}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                />
            ),
            disableFilter: false,
        },
    ];

    useEffect(() => {
        setDefaultSetting({
            ...defaultSetting,
            saleMode: JSON.parse(JSON.stringify({ data: data.data })),
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (skipFirstEffect)
            editSale(
                franchiseID,
                saleMode,
                setIsAddSaleClicked,
                setDataIsUpdated,
                dataIsUpdated
            );
        else setSkipFirstEffect(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSaleModeModified]);
    React.useEffect(() => {
        setUrl(
            `${process.env.REACT_APP_API_V2_URL}/settings/defaultSetting/getData?type=saleMode&franchiseId=${franchiseID}`
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <h2 className="mt-3 ml-3">{t("Sales method")}</h2>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: isAddSaleClicked ? "2fr 1fr " : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    canMovedCheckboxLeftOnExpand
                    url={url}
                    setData={setData}
                    canSelect
                    customSelect
                    canResize
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight={"450px"}
                    maxHeight={"450px"}
                    name="tva"
                />
                {isAddSaleClicked ? (
                    <SaleForm
                        setIsAddModeOfSaleClicked={setIsAddSaleClicked}
                        setDataIsUpdated={setDataIsUpdated}
                        dataSale={rowData}
                        dataIsUpdated={dataIsUpdated}
                        data={data.data}
                        setRowData={setRowData}
                        isAddModeOfSaleClicked={isAddSaleClicked}
                    />
                ) : null}
            </div>
        </div>
    );
}
