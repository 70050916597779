import { StyledH2 } from "@aureskonnect/react-ui";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";

import { setLoyaltyAccount } from "../store";

export default function LoyaltyAccount({ selectedRows }: any) {
    const { t } = useTranslation();
    let isActivation: boolean =
        selectedRows[0] !== undefined &&
        selectedRows[0]["ActivationLoyaltyAccount"] !== undefined
            ? selectedRows[0]["ActivationLoyaltyAccount"]
            : false;

    const [isAuthorized, setIsAuthorized] = React.useState<boolean>(
        Object.values(selectedRows).length > 0
            ? selectedRows[0].Autorisation !== undefined
                ? selectedRows[0].Autorisation
                : false
            : false
    );
    useEffect(() => {
        setLoyaltyAccount({ isAuthorized });
    }, [isAuthorized, isActivation]);

    return (
        <React.Fragment>
            <StyledH2
                style={{
                    font: "normal normal 600 30px/41px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-5 pt-3"
            >
                {t("Loyalty account")}
            </StyledH2>
            <div className="d-flex  align-items-center justify-content-between mt-5 ml-3 not-allowed-icon__clz">
                <div className="ml-5">
                    {t("Activation of the loyalty account")}
                </div>
                <div className="float-right mr-4 cac_icn_loyaltyAccountActivation">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isActivation}
                        onChange={(e: any) => {}}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-3 ml-3">
                <div className="ml-5">
                    {t("Authorize payment by loyalty points")}
                </div>
                <div className="float-right mr-4 cac_icn_authorizePaymentLoyaltyPoints">
                    <ReactSwitch
                        disabled={!isActivation}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isAuthorized}
                        onChange={(e: any) => {
                            setIsAuthorized(!isAuthorized);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
