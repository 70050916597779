import React, { useState } from "react";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next/";
import { uuid } from "uuidv4";
import { useSnapshot } from "valtio";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { useLocation } from "react-router-dom";

import { store } from "./Kiosk/store";
import { store as userStore } from "@components/VerticalLayout/store";
import {
    store as appStore,
    setIsAddAppClicked,
    setIsDeletePeripheralModalOpened,
    setGlobalDataIsUpdated,
    setIsMultiDeletePeripheralModalOpened,
    setIsActionsClicked,
    setActions,
    setValidationAction,
    setIsEdited,
    setIsLoading,
    setKey,
} from "./store";
import { store as settingPrinter } from "../ApplicationSetting/store";
import { setDataTable } from "./PeripheralConfigure/MoneticPeripheralConfigure/store";
import { setSelectedMonnyeur } from "@pages/Peripherals/CashManagement/store";
import { setModify, setAllSelectedRows } from "./store";

import { UpdateResourceIp } from "@helpers/general";
import { AlertDeletePeripheralMessage } from "@components/Common/AlertDeletePeripheralMessage";
import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import ApplicationSetting from "@pages/ApplicationSetting";
import CashManagementSetting from "@pages/ApplicationSetting/CashManagementSetting";
import { ConfirmationDeleteMessage } from "@components/Common/ConfirmationDeleteMessage";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { ConfirmationMultiDeleteMessage } from "@components/Common/ConfirmationMultiDeleteMessage";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import { getPathAuthorization } from "@components/VerticalLayout";

import { ApplicationConfigure } from "./ApplicationConfigure";
import { PeripheralConfiguration } from "./PeripheralConfigure";
import { DropdownConfigure } from "./DropdownConfigure";
import DeviceAssociateModal from "./DeviceAssociateModal";
import { MoneticValinaSetting } from "./ApplicationSettingMonetic/MoneticValinaSetting";
import AddApplication from "./AddApplication";
import ApplicationAssociateModal from "./ApplicationAssociateModal";
import { CustomIcons } from "./CustomIcons";
import { DropdownStatus } from "./DropdownStatus";
import { ApplicationHistory } from "./History/ApplicationHistory";
import { MoneticsHistory } from "@pages/Peripherals/Monetics/MoneticsHistory";
import HistoryModal from "@pages/Peripherals/CashManagement/HistoryModal";
import BadgeNumber from "@components/Common/BadgeNumber";
import OriginHistory from "@pages/Peripherals/Printers/OriginHistory";
import { ActionColumn } from "./ActionColumn";
import { ConfirmationMessage } from "@components/Common/ConfirmationMessage";
import AlertSvgIcon from "@components/Common/SvgIcons/AlertSvgIcon";
import { AppUid } from "./AppUid";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export default function Applications() {
    const { t } = useTranslation();
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const {
        oneShop,
        userID,
        franchiseID,
        shopID,
        operatorID,
        isModalConfirmationOpened,
    } = useSnapshot(userStore);
    const {
        isDeletePeripheralModalOpened,
        isAddAppClicked,
        globalDataIsUpdated,
        isMultiDeletePeripheralModalOpened,
        isEdited,
        actions,
        validationAction,
        isActionsClicked,
        modify,
        key,
    } = useSnapshot(appStore);
    const { displayMode } = useSnapshot(store);
    const { isValidated } = useSnapshot(settingPrinter);
    const [isConsult, setIsConsult] = React.useState<boolean>(false);
    const appId = "KIOSK";
    const didMountRef = React.useRef(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = useState(false);
    const [
        isHistoryModalCashMangmentOpened,
        setIsHistoryModalCashMangmentOpened,
    ] = useState(false);
    const [
        isHistoryMoneticsModalOpened,
        setIsHistoryMoneticsModalOpened,
    ] = useState(false);
    const [isAddNewAppButtonClicked, setIsAddNewAppButtonClicked] = useState(
        false
    );
    const [urlHistoryPrinter, setUrlHistoryPrinter] = useState<string>(``);

    const [
        isAddPrinterOriginHistoryClicked,
        setIsAddPrinterOriginHistoryClicked,
    ] = React.useState<boolean>(false);
    const [
        isInitializationModalOpened,
        setIsInitializationModalOpened,
    ] = useState(false);
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [
        isMoneticsPathsModalOpened,
        setIsMoneticsPathsModalOpened,
    ] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [applicationChildrenId, setApplicationChildrenId] = React.useState<
        string
    >("");
    const [selectedIds, setSelectedIds] = React.useState<any>([]);
    const [ListNumberOrigin, setListNumberOrigin] = React.useState<any>({
        nbrNotArchived: 0,
        nbrArchived: 0,
    });
    const [typeDisplay, setTypeDisplay] = React.useState<string>("notArchived");
    const [shopId, setShopId] = React.useState<number>();
    const [peripheralId, setPeripheralId] = React.useState<string>("");
    const [data, setData] = React.useState<any>({});
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isAssociateModalOpened, setIsAssociateModalOpened] = React.useState<
        boolean
    >(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");
    const [
        isConfirmationEditModalOpened,
        setIsConfirmationEditModalOpened,
    ] = React.useState<boolean>(false);
    const [isDisplayModeChanged, setIsDisplayModeChanged] = React.useState<
        boolean
    >(false);
    const { isConfigurationCashManagementClicked } = useSnapshot(store);
    const [rowData, setRowData] = useState<any>({});

    const [apiUrl, setApiUrl] = useState<any>("");
    const [textModal, setTextModal] = useState<any>("");
    const [peripheralUrl, setPeripheralUrl] = useState<string>(
        oneShop
            ? `/settings/applications/list?userId=${userID}&franchiseId=${franchiseID}&appId=${appId}&shopId=${shopID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
            : `/settings/applications?userId=${userID}&franchiseId=${franchiseID}&appId=${appId}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
    );
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [shopIds, setShopIds] = React.useState<any[]>([]);
    const [applicationsShopIds, setApplicationsShopIds] = React.useState<any[]>(
        []
    );
    const [publishedShops, setPublishedShops] = React.useState<any[]>([]);

    const [shopNames, setShopNames] = React.useState<any>([]);

    const [editedData, setEditedData] = React.useState<any>([]);
    const [isComingFromModify, setIsComingFromModify] = React.useState<boolean>(
        false
    );
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const [
        isModalDeleteAssociatePeripheralOpened,
        setIsModalDeleteAssociatePeripheralOpened,
    ] = React.useState<boolean>(false);
    const [shops, setShops] = React.useState<any>([]);
    const [redisData, setRedisData] = React.useState<any>([]);

    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: oneShop ? 1 : 2,
            columnName:
                displayMode === t("View by device")
                    ? t("Peripheral")
                    : t("Application"),
            customJsx: (e: any) => {
                return displayMode === t("View by device") ? (
                    oneShop ? (
                        e.selectedRow[t("Peripheral")]
                    ) : e.selectedRow[t("Shop")] !== "" &&
                      e.selectedRow.subRows?.length >= 0 ? (
                        <BadgeNumber value={e.selectedRow.subRows?.length} />
                    ) : (
                        e.selectedRow[t("Peripheral")]
                    )
                ) : oneShop ? (
                    e.selectedRow[t("Application")]
                ) : e.selectedRow[t("Shop")] !== "" &&
                  e.selectedRow.subRows?.length >= 0 ? (
                    <BadgeNumber value={e.selectedRow.subRows?.length} />
                ) : (
                    e.selectedRow[t("Application")]
                );
            },
        },
        {
            indexOFColumn: oneShop ? 2 : 3,
            columnName: t("UID"),
            customJsx: (e: any) => {
                return <AppUid e={e} />;
            },
        },
        {
            indexOFColumn: oneShop
                ? displayMode === t("View by device")
                    ? 2
                    : 3
                : displayMode === t("View by device")
                ? 3
                : 4,
            columnName:
                displayMode !== t("View by device")
                    ? t("Peripheral")
                    : t("Application"),
            customJsx: (e: any) => {
                return displayMode !== t("View by device")
                    ? e.selectedRow[t("Peripheral")] !== undefined
                        ? e.selectedRow[t("Peripheral")]
                        : ""
                    : e.selectedRow[t("Application")];
            },
        },
        {
            indexOFColumn: 997,
            columnName: t("Status"),
            customJsx: (e: any) => {
                return e.selectedRow["Application"] !== "" &&
                    e.selectedRow[t("Shop")] === "" ? (
                    <DropdownStatus
                        state="In line"
                        typeDisplay={typeDisplay}
                        data={e.selectedRow}
                        franchiseID={franchiseID}
                    />
                ) : e.selectedRow[t("Peripheral")] !== "" &&
                  e.selectedRow[t("Shop")] === "" ? (
                    <DropdownStatus
                        state="In line"
                        typeDisplay={typeDisplay}
                        data={e.selectedRow}
                        franchiseID={franchiseID}
                    />
                ) : null;
            },
        },
        {
            indexOFColumn: oneShop ? 6 : 7,
            columnName: t("Ticket"),
            customJsx: (e: any) => {
                return e.selectedRow["tickets"] !== undefined &&
                    e.selectedRow["tickets"] !== "" &&
                    e.selectedRow[t("Application")] === ""
                    ? t(e.selectedRow["tickets"].label)
                    : null;
            },
        },
        {
            indexOFColumn: 998,
            columnName: t("Setup status"),
            customJsx: (e: any) => {
                return e.selectedRow["Application"] !== "" &&
                    e.selectedRow[t("Shop")] === "" ? (
                    <DropdownConfigure
                        state={e.selectedRow[t("State")]}
                        typeDisplay={typeDisplay}
                        data={e.selectedRow}
                    />
                ) : e.selectedRow[t("Peripheral")] !== "" &&
                  e.selectedRow[t("Shop")] === "" ? (
                    <DropdownConfigure
                        state={e.selectedRow[t("State")]}
                        typeDisplay={typeDisplay}
                        data={e.selectedRow}
                    />
                ) : null;
            },
        },
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                return (
                    <CustomIcons
                        e={e}
                        setShopId={setShopId}
                        setRowData={setRowData}
                        setPeripheralId={setPeripheralId}
                        setIsModalOpened={setIsModalOpened}
                        isModalOpened={isModalOpened}
                        setIsMoneticsPathsModalOpened={
                            setIsMoneticsPathsModalOpened
                        }
                        isMoneticsPathsModalOpened={isMoneticsPathsModalOpened}
                        setIsAssociateModalOpened={setIsAssociateModalOpened}
                        isAssociateModalOpened={isAssociateModalOpened}
                        setIsDeletePeripheralModalOpened={
                            setIsDeletePeripheralModalOpened
                        }
                        setApplicationChildrenId={setApplicationChildrenId}
                        setApiUrl={setApiUrl}
                        isConfigurationCashManagementClicked={
                            isConfigurationCashManagementClicked
                        }
                        setIsModalDeleteAssociatePeripheralOpened={
                            setIsModalDeleteAssociatePeripheralOpened
                        }
                        setTextModal={setTextModal}
                        typeDisplay={typeDisplay}
                        setSelectedRows={setSelectedRows}
                        setIsHistoryModalOpened={setIsHistoryModalOpened}
                        setIsConsult={setIsConsult}
                        setEditedData={setEditedData}
                        setIsComingFromModify={setIsComingFromModify}
                        setIsHistoryMoneticsModalOpened={
                            setIsHistoryMoneticsModalOpened
                        }
                        setIsHistoryModalCashMangmentOpened={
                            setIsHistoryModalCashMangmentOpened
                        }
                        setUrlHistoryPrinter={setUrlHistoryPrinter}
                        setIsAddPrinterOriginHistoryClicked={
                            setIsAddPrinterOriginHistoryClicked
                        }
                        selectedRows={selectedRows}
                    />
                );
            },
        },
    ];

    async function getPublish() {
        const getShops: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/application/publishedShops?franchiseId=${franchiseID}`
        );
        const shopList: any = await getShops.json();
        setShops(shopList.data.map((el: any) => el.shopId));
        setPublishedShops(shopList.data);
    }

    React.useEffect(() => {
        if (oneShop) {
            if (displayMode === t("View by device")) {
                setPeripheralUrl(
                    `/settings/applications/oneShop/devices?userId=${userID}&franchiseId=${franchiseID}&appId=${appId}&shopId=${shopID}&typeDisplay=${typeDisplay}`
                );
            } else {
                setPeripheralUrl(
                    `/settings/applications/list?userId=${userID}&franchiseId=${franchiseID}&appId=${appId}&shopId=${shopID}&typeDisplay=${typeDisplay}`
                );
            }
        } else {
            if (displayMode === t("View by device")) {
                setPeripheralUrl(
                    `/settings/applications/devices?userId=${userID}&franchiseId=${franchiseID}&appId=${appId}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
                );
            } else {
                setPeripheralUrl(
                    `/settings/applications?userId=${userID}&franchiseId=${franchiseID}&appId=${appId}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
                );
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        displayMode,
        oneShop,
        franchiseID,
        shopID,
        userID,
        isValidated,
        typeDisplay,
    ]);

    React.useEffect(() => {
        setIsAddAppClicked(false);
        setEditedData([]);
        if (didMountRef.current) {
            setGlobalDataIsUpdated(true);
        } else {
            didMountRef.current = true;
        }
    }, [i18nextLng]);

    React.useEffect(() => {
        if (globalDataIsUpdated) {
            setGlobalDataIsUpdated(false);
        }
    }, [globalDataIsUpdated]);

    React.useEffect(() => {
        setDataTable(data.data);
        setListNumberOrigin(data?.othersData?.ListNumberOrigin);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        UpdateResourceIp(userID, franchiseID);
        // eslint-disable-next-line
    }, [globalDataIsUpdated, data]);

    React.useEffect(() => {
        setIsEdited(false);
        if (isAddAppClicked === false) setEditedData([]);
    }, [isAddAppClicked]);

    React.useEffect(() => {
        let ids: any[] = [];
        let selectedShopsIds: any[] = [];
        let shopsNames: any = [];
        let applicationShopIds: any[] = [];
        selectedRows.forEach((el: any) => {
            if (
                displayMode === t("View by device") &&
                el.subRows === undefined
            ) {
                ids.push({
                    Id: el.appId,
                    isArchived:
                        typeDisplay === "notArchived" ? "true" : "false",
                    shopId: el.shopId,
                    userID: userID,
                    franchiseID: franchiseID,
                });
            } else if (
                displayMode !== t("View by device") &&
                (el[t("Shop")] === "" || el[t("Shop")] === undefined) &&
                (!oneShop || (oneShop && el.subRows !== undefined))
            ) {
                ids.push({
                    Id: el.appId,
                    isArchived:
                        typeDisplay === "notArchived" ? "true" : "false",
                    shopId: el.shopId,
                    userID: userID,
                    franchiseID: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                });
            } else if (
                oneShop &&
                el.subRows === undefined &&
                displayMode !== t("View by device") &&
                el[t("Application")] === ""
            ) {
                ids.push({
                    Id: el.appId,
                    isArchived:
                        typeDisplay === "notArchived" ? "true" : "false",
                    shopId: shopID,
                    userID: userID,
                    franchiseID: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                });
            } else if (
                oneShop &&
                el.subRows === undefined &&
                displayMode !== t("View by device") &&
                el[t("Peripheral")] === ""
            ) {
                ids.push({
                    Id: el.appId,
                    isArchived:
                        typeDisplay === "notArchived" ? "true" : "false",
                    shopId: shopID,
                    userID: userID,
                    franchiseID: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                });
            }

            if (el[t("Shop")] !== "") {
                selectedShopsIds.push(el.shopId);
                if (shops.includes(el.shopId)) {
                    shopsNames.push(el[t("Shop")]);
                }
            } else {
                applicationShopIds.push(el.shopId);
                let exist: any = publishedShops.find(
                    (elt: any) => elt.shopId === el.shopId
                );
                if (exist !== undefined) {
                    shopsNames.push(exist.name);
                }
            }
        });
        let array: any[] = [];
        setSelectedIds(ids);
        setRedisData(ids.filter((el: any) => shops.includes(el.shopId)));
        setShopIds(selectedShopsIds);
        setApplicationsShopIds(applicationShopIds);
        setShopNames([...new Set(shopsNames)]);
        selectedRows.forEach((element: any) => {
            array.push({ [element.shopId]: element.peripheralId });
        });
        setSelectedMonnyeur(array);
        setAllSelectedRows(selectedRows);
        // eslint-disable-next-line
    }, [selectedRows]);

    React.useEffect(() => {
        if (isAddAppClicked && didMountRef.current) {
            setActions(confirmNavigation);
            setIsActionsClicked(showPrompt as boolean);
            setValidationAction(cancelNavigation);
        } else {
            didMountRef.current = true;
        }
    }, [isAddAppClicked, showPrompt, confirmNavigation, cancelNavigation]);

    React.useEffect(() => {
        setKey(uuid());
    }, [peripheralUrl]);

    React.useEffect(() => {
        getPublish();
        //eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <PageTitle title={t("Applications")} />
            <div className="page-content__clz">
                <StyledBreadcrumb
                    items={[
                        {
                            item: "Settings".toUpperCase(),
                            link: `${process.env.REACT_APP_PORTAIL_URL}`,
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: oneShop
                                ? "/setting/customer-account"
                                : "/compte",
                        },
                        {
                            item: t("Application").toUpperCase(),
                            link: "applications",
                        },
                        {
                            item: t("KIOSK").toUpperCase(),
                            link: "kiosk",
                        },
                        {
                            item: t(
                                typeDisplay === "notArchived"
                                    ? "Peripheral setting"
                                    : "Archived peripheral setting"
                            ).toUpperCase(),
                            link: "#",
                        },
                    ]}
                />
                <MessageInfo
                    message={t(
                        "In this section, you can perform the declaration and configuration of the store's kiosks, as well as peripherals such as printers and electronic payment terminals (TPE)."
                    )}
                />

                <div
                    style={{
                        display: "grid",
                        minHeight: "650px",
                        gridTemplateColumns: isAddAppClicked
                            ? "2fr 1fr "
                            : "auto",
                        gridColumnGap: "4px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        key={key}
                        showFilter
                        url={`${process.env.REACT_APP_API_V2_URL}${peripheralUrl}`}
                        name={displayMode}
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        customJsxSideFilterButton={
                            <AddApplication
                                setIsComingFromModify={setIsComingFromModify}
                                isComingFromModify={isComingFromModify}
                                isAddAppClicked={isAddAppClicked}
                                setIsAddAppClicked={setIsAddAppClicked}
                                selectedRows={selectedRows}
                                isEdited={isEdited}
                                setIsInitializationModalOpened={
                                    setIsInitializationModalOpened
                                }
                                oneShop={oneShop}
                                setDataIsUpdated={setGlobalDataIsUpdated}
                                typeDisplay={typeDisplay}
                                setTypeDisplay={setTypeDisplay}
                                selectedIds={selectedIds}
                                ListNumberOrigin={ListNumberOrigin}
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                shopIds={shopIds}
                                setIsAssociateModalOpened={
                                    setIsAssociateModalOpened
                                }
                                isDisplayModeChanged={isDisplayModeChanged}
                                setIsDisplayModeChanged={
                                    setIsDisplayModeChanged
                                }
                            />
                        }
                        setDataIsUpdated={
                            setGlobalDataIsUpdated as
                                | React.Dispatch<
                                      React.SetStateAction<number | boolean>
                                  >
                                | undefined
                        }
                        dataIsUpdated={globalDataIsUpdated}
                        canResize
                        actionColumn={(e: any) =>
                            modify === true &&
                            e.selectedRow.original.mark !== "Adyen" ? (
                                <>
                                    <ActionColumn
                                        e={e}
                                        displayMode={displayMode}
                                        typeDisplay={typeDisplay}
                                    />
                                </>
                            ) : null
                        }
                        setData={setData}
                        showGlobalFilter
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        filterActive={filterActive}
                        setLocalFilterActive={setLocalFilterActive}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        canExpand={true}
                        canSelect={true}
                        customSelect
                        setSelectedRows={setSelectedRows}
                        canMovedCheckboxLeftOnExpand
                    />

                    {displayMode !== t("View by device") && isAddAppClicked ? (
                        <ApplicationConfigure
                            setIsAddAppClicked={setIsAddAppClicked!}
                            isAddAppClicked={isAddAppClicked}
                            setDataIsUpdated={setGlobalDataIsUpdated}
                            isAddNewAppButtonClicked={isAddNewAppButtonClicked}
                            selectedRows={
                                !isComingFromModify
                                    ? selectedRows[0]
                                    : editedData
                            }
                            isComingFromModify={isComingFromModify}
                            inputDesignation={inputDesignation}
                            setInputDesignation={setInputDesignation}
                            setIsConfirmationEditModalOpened={
                                setIsConfirmationEditModalOpened
                            }
                            data={data}
                            isConsult={isConsult}
                            setIsConsult={setIsConsult}
                            shopIds={shopIds}
                            setSelectedRows={setSelectedRows}
                        />
                    ) : displayMode === t("View by device") &&
                      isAddAppClicked ? (
                        <PeripheralConfiguration
                            setIsAddPeripheralClicked={setIsAddAppClicked!}
                            isAddPeripheralClicked={isAddAppClicked}
                            setDataIsUpdated={setGlobalDataIsUpdated}
                            isAddNewPeripheralButtonClicked={
                                isAddNewAppButtonClicked
                            }
                            selectedRows={
                                !isComingFromModify
                                    ? selectedRows.filter(
                                          (el: any) => el[t("Shop")] !== ""
                                      )[0]
                                    : editedData
                            }
                            isComingFromModify={isComingFromModify}
                            setEditedData={setEditedData}
                            setIsEdited={setIsEdited}
                            isEdited={isEdited}
                            editedData={editedData}
                            shopIds={shopIds}
                            data={data}
                            isConsult={isConsult}
                            setIsConsult={setIsConsult}
                        />
                    ) : null}

                    {/* <ToastContainer limit={1} /> */}
                    <ApplicationSetting rowData={rowData} oneShop={oneShop} />

                    <CashManagementSetting
                        rowData={rowData}
                        setRowData={setRowData}
                        oneShop={oneShop}
                        setDataIsUpdated={setGlobalDataIsUpdated}
                    />
                </div>
            </div>
            {displayMode !== t("View by device") ? (
                <DeviceAssociateModal
                    setIsDeviceAssociateModalOpened={setIsAssociateModalOpened}
                    isDeviceAssociateModalOpened={isAssociateModalOpened}
                    rowData={rowData}
                    dataIsUpdated={globalDataIsUpdated}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    oneShop={oneShop}
                    selectedRows={selectedRows}
                />
            ) : (
                <ApplicationAssociateModal
                    setIsDeviceAssociateModalOpened={setIsAssociateModalOpened}
                    isDeviceAssociateModalOpened={isAssociateModalOpened}
                    rowData={rowData}
                    dataIsUpdated={globalDataIsUpdated}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    selectedRows={selectedRows}
                />
            )}
            {isMultiDeletePeripheralModalOpened === true && (
                <ConfirmationMultiDeleteMessage
                    isModalOpened={isMultiDeletePeripheralModalOpened}
                    setIsModalOpened={setIsMultiDeletePeripheralModalOpened}
                    appMessage={
                        typeDisplay === "notArchived"
                            ? oneShop && shops.includes(shopID)
                                ? t(
                                      "Please note, one or more applications are associated with a published Template."
                                  )
                                : shops.some((el: any) =>
                                      applicationsShopIds.includes(el)
                                  )
                                ? `${t(
                                      "Please note, one or more applications are associated with a published Template. See the stores:"
                                  )}  ${shopNames.join()}`
                                : ""
                            : ""
                    }
                    redisData={redisData}
                />
            )}
            {isModalDeleteAssociatePeripheralOpened === true ? (
                <AlertDeletePeripheralMessage
                    text={textModal}
                    isModalDeleteAssociatePeripheralOpened={
                        isModalDeleteAssociatePeripheralOpened
                    }
                    setIsModalDeleteAssociatePeripheralOpened={
                        setIsModalDeleteAssociatePeripheralOpened
                    }
                />
            ) : oneShop === true ? (
                <ConfirmationDeleteMessage
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    apiUrl={apiUrl}
                    text={
                        isDeletePeripheralModalOpened === true ? (
                            displayMode !== t("View by device") ? (
                                t("Please confirm unpairing this device")
                            ) : rowData.subRows !== undefined &&
                              rowData.subRows.length > 0 ? (
                                <div className="d-flex flex-column">
                                    <span>
                                        {t(
                                            "This device contains associated applications"
                                        )}
                                        !
                                    </span>
                                    <span>
                                        {t(
                                            "Please confirm archiving of this device"
                                        )}
                                    </span>
                                </div>
                            ) : (
                                t("Please confirm archiving of this device")
                            )
                        ) : typeDisplay === "notArchived" &&
                          displayMode !== t("View by device") ? (
                            t(
                                "Do you want to confirm the archiving of the selected application?"
                            )
                        ) : typeDisplay === "notArchived" &&
                          displayMode === t("View by device") ? (
                            t(
                                "Do you want to confirm the dissociation of the selected application?"
                            )
                        ) : (
                            t(
                                "Do you want to confirm the unarchiving of the selected application?"
                            )
                        )
                    }
                    message={
                        typeDisplay === "notArchived"
                            ? displayMode !== t("View by device")
                                ? t(
                                      "Unpairing of this device has been successfully completed"
                                  )
                                : t(
                                      "Unlinking of this application has been successfully completed"
                                  )
                            : displayMode === t("View by device")
                            ? t("The device was successfully unarchived")
                            : t("This application was successfully unarchived")
                    }
                    appMessage={
                        <div className="d-flex flex-column">
                            {typeDisplay === "notArchived" &&
                            displayMode !== t("View by device") &&
                            shops.includes(shopId) ? (
                                <span className="d-flex align-item-between">
                                    <AlertSvgIcon className="mr-1" />
                                    <div style={{ color: "#e31111" }}>
                                        {t(
                                            "Please note, this application is associated with a published Template."
                                        )}
                                    </div>
                                </span>
                            ) : null}
                        </div>
                    }
                    data={{
                        userId: userID,
                        shopId: shopId,
                        franchiseId: franchiseID,
                        appId: appId,
                        appId_children: [applicationChildrenId],
                        peripheralId: peripheralId,
                        peripheralName:
                            displayMode === t("View by device")
                                ? rowData.peripheral
                                : rowData[t("Peripheral")],
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        isArchived:
                            typeDisplay === "notArchived" ? true : false,
                        applicationName: [rowData.appName],
                    }}
                    setKey={setKey}
                    setIsLoading={setIsLoading}
                />
            ) : (
                <ConfirmationDeleteMessage
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    apiUrl={apiUrl}
                    message={
                        isDeletePeripheralModalOpened === true
                            ? t(
                                  "Unpairing of this device has been successfully completed"
                              )
                            : t(
                                  "Unlinking of this application has been successfully completed"
                              )
                    }
                    appMessage={
                        !isDeletePeripheralModalOpened &&
                        displayMode !== t("View by device") &&
                        (rowData.subRows === undefined ||
                            rowData.subRows.length === 0) &&
                        typeDisplay === "notArchived" &&
                        shops.includes(shopId) ? (
                            <div className="d-flex flex-column">
                                <span className="d-flex align-item-between">
                                    <AlertSvgIcon className="mr-1" />
                                    <div style={{ color: "#e31111" }}>
                                        {t(
                                            "Please note, this application is associated with a published Template."
                                        )}
                                    </div>
                                </span>
                            </div>
                        ) : null
                    }
                    text={
                        isDeletePeripheralModalOpened === true ? (
                            displayMode !== t("View by device") ? (
                                t("Please confirm unpairing this device")
                            ) : rowData.subRows !== undefined &&
                              rowData.subRows.length > 0 ? (
                                <div className="d-flex flex-column">
                                    <span>
                                        {t(
                                            "This device contains associated applications"
                                        )}
                                        !
                                    </span>
                                    <span>
                                        {t(
                                            "Please confirm deletion of this device"
                                        )}
                                    </span>
                                </div>
                            ) : (
                                t("Please confirm deletion of this device")
                            )
                        ) : rowData.subRows !== undefined &&
                          rowData.subRows.length > 0 ? (
                            <div className="d-flex flex-column">
                                <span>
                                    {t(
                                        "This application contains associated devices!"
                                    )}
                                </span>
                                <span>
                                    {t(
                                        typeDisplay === "notArchived"
                                            ? "Do you want to confirm the archiving of the selected application?"
                                            : "Do you want to confirm the unarchiving of the selected application?"
                                    )}
                                </span>
                            </div>
                        ) : displayMode !== t("View by device") ? (
                            t(
                                typeDisplay === "notArchived"
                                    ? "Do you want to confirm the archiving of the selected application?"
                                    : "Do you want to confirm the unarchiving of the selected application?"
                            )
                        ) : (
                            t("Please confirm unpairing of the application")
                        )
                    }
                    data={{
                        userId: userID,
                        shopId: shopId,
                        franchiseId: franchiseID,
                        appId: appId,
                        appId_children:
                            applicationChildrenId !== ""
                                ? typeof applicationChildrenId === "string"
                                    ? [applicationChildrenId]
                                    : [...applicationChildrenId]
                                : [],
                        peripheralId: peripheralId,
                        peripheralName:
                            displayMode === t("View by device")
                                ? rowData.peripheral
                                : rowData[t("Peripheral")],
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        isArchived:
                            typeDisplay === "notArchived" ? true : false,
                        applicationName: [rowData.appName],
                    }}
                    setKey={setKey}
                    setIsLoading={setIsLoading}
                />
            )}

            <MoneticValinaSetting
                data={rowData}
                infoData={{
                    userId: userID,
                    shopId: shopId,
                    franchiseId: franchiseID,
                    appId: appId,
                    appId_children: applicationChildrenId,
                }}
                setIsMoneticsPathsModalOpened={setIsMoneticsPathsModalOpened}
                isMoneticsPathsModalOpened={isMoneticsPathsModalOpened}
                setDataIsUpdated={setGlobalDataIsUpdated}
                displayMode={displayMode}
            />
            {isModalConfirmationOpened ? <ConfirmationMessage /> : null}
            {isInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInitializationModalOpened}
                    setIsModalOpened={setIsInitializationModalOpened}
                    setIsClicked={setIsAddNewAppButtonClicked}
                    isClicked={isAddNewAppButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isConfirmationEditModalOpened ? (
                <ConfirmationEditMessage
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    isModalOpened={isConfirmationEditModalOpened}
                    setIsModalOpened={setIsConfirmationEditModalOpened}
                    isAddClicked={isAddAppClicked}
                    setIsAddClicked={setIsAddAppClicked}
                />
            ) : null}
            {isHistoryModalOpened ? (
                <ApplicationHistory
                    selectedRows={selectedRows}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    setDataShopIsUpdated={setGlobalDataIsUpdated}
                    displayMode={displayMode}
                />
            ) : null}
            {isHistoryMoneticsModalOpened ? (
                <MoneticsHistory
                    selectedRows={selectedRows}
                    isHistoryModalOpened={isHistoryMoneticsModalOpened}
                    setIsHistoryModalOpened={setIsHistoryMoneticsModalOpened}
                    setGlobalDataUpdated={setGlobalDataIsUpdated}
                    displayMode={displayMode}
                />
            ) : null}
            {isHistoryModalCashMangmentOpened ? (
                <HistoryModal
                    isModalOpened={isHistoryModalCashMangmentOpened}
                    setIsModalOpened={setIsHistoryModalCashMangmentOpened}
                    setGlobalDataUpdated={setGlobalDataIsUpdated}
                />
            ) : null}
            {isAddPrinterOriginHistoryClicked && (
                <OriginHistory
                    url={urlHistoryPrinter}
                    setIsModalOpened={setIsAddPrinterOriginHistoryClicked}
                    isModalOpened={isAddPrinterOriginHistoryClicked}
                />
            )}
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
        </React.Fragment>
    );
}
