import { proxy } from "valtio";

type StoreType = {
    isModified: boolean;
    isActivation: boolean;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    isPlusIconClicked: boolean;
    modify: boolean;
    activeSubTab: number;

};

export const store = proxy<StoreType>({
    isModified: false,
    isActivation: false,
    isActionsClicked: false,
    isEdited: false,
    actions: () => { },
    validationAction: {},
    isPlusIconClicked: false,
    modify: false,
    activeSubTab: 0
});
export function setModify(value: boolean): void {
    store.modify = value;
}

export function setIsModified(isModified: boolean): void {
    store.isModified = isModified;
}
export function setIsActivation(isActivation: boolean): void {
    store.isActivation = isActivation;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}
export function setIsPlusIconClicked(isPlusIconClicked: boolean): void {
    store.isPlusIconClicked = isPlusIconClicked;
}
export function setActiveSubTab(activeSubTab: number): void {
    store.activeSubTab = activeSubTab;
}
export default store;
