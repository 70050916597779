import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import moment from "moment";

import {
    setLimitToast,
    store as userStore,
} from "@components/VerticalLayout/store";
import { setKey } from "./store";
import { setIsLoading } from "../../store/project/index";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import ErrorToast from "@components/Common/ErrorTost";
import { generalConfigStore } from "@store";

type ConfirmationArchiveType = {
    setIsDataUpdated: Function;
    setIsModalArchiveOpened: Function;
    isModalArchiveOpened: boolean;
    selectedRows: any;
    isArchivedTemplateClicked: boolean;
    dataTable: any;
};

export function ConfirmationArchiveTemplate({
    setIsDataUpdated,
    setIsModalArchiveOpened,
    isModalArchiveOpened,
    selectedRows,
    isArchivedTemplateClicked,
}: ConfirmationArchiveType): JSX.Element {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID, oneShop, shopID } = useSnapshot(
        userStore
    );
    const { project } = useSnapshot(generalConfigStore);
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState(false);
    const uuidUser = localStorage.getItem("uuidUser");

    async function archiveTemplate() {
        let apiUrlPlan = `${process.env.REACT_APP_API_V2_URL}/settings/project`;
        toast.dismiss();
        setIsLoading(true);
        let localData: any = [];
        if (selectedRows.length > 0) {
            for (let el of selectedRows) {
                const id: any = el.projectId;
                if (el.template === undefined) {
                    let apiUrl = `${
                        process.env.REACT_APP_API_V2_URL
                    }/settings/projectData?userId=106&shopId=${
                        oneShop ? shopID : el?.shopId ?? el?.id_boutique
                    }&franchiseId=${franchiseID}&projectId=${
                        el?.id_project ?? el?.projectId
                    }`;
                    try {
                        mutate(
                            apiUrl,
                            await fetch(apiUrl, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bareer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                method: "GET",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    el.template = data[0].template;
                                    el.files = data[0].files;
                                })
                        );
                    } catch (e: any) {}
                }
                let savedData = {
                    userId: userID,
                    franchiseId: franchiseID,
                    shopId: "0",
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    etat: "Archivage",
                    data: {
                        PROJECT: {
                            project_id: "PROJECT",
                            data_project: {
                                [id]: {
                                    projectId: id,
                                    designation: el[t("Designation")],
                                    associates: el.associates,
                                    template: el.template,
                                    files: el.files,
                                    note: el[t("Remark")],
                                    nombre: el.Nombre,
                                    isArchived: true,
                                    status: "en attente",
                                    hourlyInterval: el.hourlyInterval,
                                    definedTime: el.definedTime,
                                    startDate: el.startDate,
                                    time: el.time,
                                    days: el.days,
                                    date: el.date,
                                    modified_at: `${moment().format(
                                        "MM/DD/YY [at] HH:mm a"
                                    )}`,
                                    hour: `${moment().format(
                                        "DD/MM/YY - HH:mm a"
                                    )}`,
                                    "Template type": el["Template type"],
                                },
                            },
                        },
                    },
                };
                localData.push(
                    fetch(apiUrlPlan, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(savedData),
                    }).then((response) => response.json())
                );
            }
            try {
                mutate(
                    apiUrlPlan,
                    await Promise.all(localData).then((result: any) => {
                        if (result.every((el: any) => el.error === false)) {
                            setIsModalArchiveOpened(!isModalArchiveOpened);
                            toast.success(
                                `${t(
                                    "Template have been successfully archived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsLoading(false);
                            setKey(Math.floor(Math.random() * 10000));
                        } else {
                            throw Error(result.message);
                        }
                    })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function deleteShop() {
        let apiUrlPlan = `${process.env.REACT_APP_API_V2_URL}/settings/projects/delete`;
        toast.dismiss();
        let localData: any = [];

        selectedRows.forEach(async (el: any) => {
            el.shopKey.forEach((e: any) => {
                let savedData = {
                    userId: userID,
                    franchiseId: franchiseID,
                    shopId: e,
                    projectId: "PROJECT",
                    operatorId: operatorID,
                    project_id: el.projectId,
                };
                localData.push(
                    fetch(apiUrlPlan, {
                        method: "delete",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(savedData),
                    }).then((response) => response.json())
                );
            });
        });
        try {
            mutate(
                apiUrlPlan,
                await Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                    }
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function activeTemplate() {
        toast.dismiss();
        setIsLoading(true);

        let localData: any[] = [];
        let inactiveItems: any[] = [];

        toast.info(
            t(
                "Please note that the template has been updated in accordance with the general settings."
            ),
            {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            }
        );

        if (selectedRows.length === 0) {
            setIsLoading(false);
            toast.warn(t("No rows selected"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return;
        }

        try {
            // 🚀 Fetch all required data in parallel
            const [saleMethods, paymentMethods] = await Promise.all([
                fetchData(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=0`
                ),
                fetchData(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/reglement?franchiseId=${franchiseID}&shopId=0`
                ),
            ]);

            // 🚀 First, fetch template data for all selected rows
            await Promise.all(selectedRows.map(fetchTemplateData));

            // 🚀 Now, process rows after fetching templates
            const promises = selectedRows.map(async (el: any) => {
                const id = el.projectId;

                // 🚀 Check general settings based on the updated template
                let inactive = await getDataFromGeneralSetting(
                    el.template.content,
                    saleMethods,
                    paymentMethods
                );

                if (inactive.length > 0) {
                    inactiveItems.push(...inactive);
                } else {
                    const savedData = prepareSavedData(el, id);
                    // localData.push(postSavedData(savedData));
                    localData.push(savedData);
                }
            });

            await Promise.all(promises); // Wait for all checks

            // 🚀 Handle inactive items
            handleInactiveItems(inactiveItems);

            // 🚀 Post all valid data concurrently
            await postDataSequentially(localData);
        } catch (error) {
            console.error("❌ Unexpected error in activeTemplate:", error);
        } finally {
            setIsLoading(false);
            setIsModalArchiveOpened(false);
        }
    }

    async function fetchTemplateData(el: any) {
        const shopId = oneShop ? shopID : el?.shopId ?? el?.id_boutique;
        const projectId = el?.id_project ?? el?.projectId;
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projectData?userId=106&shopId=${shopId}&franchiseId=${franchiseID}&projectId=${projectId}`;

        try {
            const data = await fetchWithTimeout(
                apiUrl,
                {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                    method: "GET",
                },
                10000
            );

            el.template = data[0].template;
            el.files = data[0].files;

            localStorage.setItem(
                "project",
                JSON.stringify({
                    ...project,
                    template: data[0].template,
                    files: data[0].files,
                })
            );
            return el;
        } catch (e) {
            console.error("Error fetching template data:", e);
        }
    }

    function prepareSavedData(el: any, id: any) {
        return {
            userId: userID,
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            shopId: "0",
            etat: "Désarchivage",
            data: {
                PROJECT: {
                    project_id: "PROJECT",
                    data_project: {
                        [id]: {
                            projectId: id,
                            designation: el[t("Designation")],
                            associates: {},
                            template: el.template,
                            files: el.files,
                            note: el[t("Remark")],
                            nombre: el.Nombre,
                            isArchived: false,
                            hourlyInterval: el.hourlyInterval,
                            definedTime: el.definedTime,
                            startDate: el.startDate,
                            time: el.time,
                            days: el.days,
                            date: el.date,
                            status: el.Status,
                            modified_at: `${moment().format(
                                "MM/DD/YY [at] HH:mm a"
                            )}`,
                            hour: `${moment().format("DD/MM/YY - HH:mm a")}`,
                            "Template type": el["Template type"],
                        },
                    },
                },
            },
        };
    }

    async function postSavedData(savedData: any) {
        const apiUrlPlan = `${process.env.REACT_APP_API_V2_URL}/settings/project`;

        try {
            const response = await fetch(apiUrlPlan, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
            });
            const result = await response.json();

            // Show success or error toast based on API response
            if (response.ok) {
                toast.success(
                    `${t("Template have been successfully activated")!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setKey(Math.floor(Math.random() * 10000));
            } else {
                toast.error(
                    `Failed to save data: ${result.message || "Unknown error"}`,
                    {
                        position: "top-right",
                        autoClose: 3000,
                    }
                );
            }
        } catch (e) {
            console.error("Error posting saved data:", e);
        }
    }

    async function postDataSequentially(localData: any[]) {
        if (localData.length === 0) return;

        try {
            await postSavedData(localData);
        } catch (error) {
            console.error(
                "❌ Unexpected error in postDataConcurrently:",
                error
            );
        }
    }

    function handleInactiveItems(inactiveItems: any[]) {
        inactiveItems = [...new Set(inactiveItems)];
        let message: string = "";
        inactiveItems.forEach((item) => {
            message += `, ${item}`;
        });
        message = message.substring(1);

        if (inactiveItems.length > 0) {
            setLimitToast(2);
            ErrorToast(
                `${t(
                    "Please note, the unarchiving could not be carried out successfully. Please check your general settings:",
                    { items: message }
                )}`
            );
        }
    }

    async function fetchWithTimeout(
        url: string,
        options: RequestInit,
        timeout: number
    ) {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), timeout);

        try {
            const response = await fetch(url, {
                ...options,
                signal: controller.signal,
            });
            return await response.json();
        } catch (e: any) {
            if (e.name === "AbortError") {
                console.error("Request timed out");
            } else {
                console.error("Request failed", e);
            }
            throw e;
        } finally {
            clearTimeout(timeoutId);
        }
    }

    async function getDataFromGeneralSetting(
        content: any,
        saleMethods: any,
        paymentMethods: any
    ) {
        let inactiveItems: any = [];

        const isSaleActive = content.salesMethods.items.some(
            (el: any) =>
                el.active === true &&
                saleMethods.find(
                    (item: any) =>
                        item.id === el.id &&
                        el.id !== "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                )
        );
        if (!isSaleActive) inactiveItems.push(t("Sale Mode"));

        const isPaymentActive = content.meansOfPayment.items.some(
            (el: any) =>
                el.active === true &&
                paymentMethods.find((item: any) => item.id === el.id)
        );
        if (!isPaymentActive) inactiveItems.push(t("Payment methods"));

        return inactiveItems;
    }

    async function fetchData(url: string) {
        const response = await fetch(url);
        return response.json();
    }

    return (
        <StyledModal toggle={true} isOpen={isModalArchiveOpened} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalArchiveOpened(!isModalArchiveOpened);
                    setIsDataUpdated(true);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {!isArchivedTemplateClicked
                        ? selectedRows.length > 1
                            ? t("Please confirm templates archive")
                            : t("Please confirm template archive")
                        : t("Please confirm template active")}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    className="cmn_btn_cancelAlert"
                    onClick={() => {
                        setIsModalArchiveOpened(!isModalArchiveOpened);
                        setIsDataUpdated(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2 cmn_btn_validateAlert"
                    variant="primary"
                    disabled={isValidateButtonDisabled}
                    onClick={async () => {
                        setIsValidateButtonDisabled(true);
                        if (!isArchivedTemplateClicked) {
                            await archiveTemplate();
                            if (oneShop) deleteShop();
                        } else {
                            await activeTemplate();
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
