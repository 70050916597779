import React from "react";
import { Tooltip } from "@mui/material";
import ReactSwitch from "react-switch";
import { CheckboxIcon, HeartIcon, PencilIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";

import { store } from "../store";
import { store as globalStore } from "@components/VerticalLayout/store";

import { editVateRate } from "./helper";

export function CustomIcons({
    e,
    setIsAddVatRateClicked,
    setRowData,
    setDataIsUpdated,
    dataIsUpdated,
}: any): JSX.Element {
    const { t } = useTranslation();

    const { defaultSetting } = useSnapshot(store);
    const { franchiseID } = useSnapshot(globalStore);

    const handleHeartClick = () => {
        const updatedRows = defaultSetting.tva?.data?.map((row: any) => {
            if (row.Id === e.selectedRow.Id) {
                return { ...row, isFavorite: true };
            }
            return { ...row, isFavorite: false };
        });
        editVateRate(
            franchiseID,
            updatedRows,
            dataIsUpdated,
            setDataIsUpdated,
            setIsAddVatRateClicked
        );
        toast.success(`${t("Favorite state has been enabled successfully")}`, {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            icon: <CheckboxIcon height={25} width={25} fill="white" />,
        });
    };

    const handlePencilClick = () => {
        setRowData(e.selectedRow);
        setIsAddVatRateClicked(true);
    };

    const handleSwitchChange = (checked: boolean) => {
        const updatedRows = defaultSetting.tva?.data?.map((row: any) => {
            if (row.Id === e.selectedRow.Id) {
                return { ...row, status: checked };
            }
            return row;
        });
        editVateRate(
            franchiseID,
            updatedRows,
            dataIsUpdated,
            setDataIsUpdated,
            setIsAddVatRateClicked
        );
        toast.success(
            `${t(
                checked
                    ? "The vat rate is successfully activate"
                    : "The vat rate is successfully deactivate"
            )}`,
            {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            }
        );
    };

    return (
        <div className="d-flex justify-content-around">
            <Tooltip
                title={
                    e.selectedRow.code === "" || e.selectedRow.value === ""
                        ? ""
                        : e.selectedRow.status === false
                        ? t("Activate")
                        : t("Unactivate")
                }
                arrow
            >
                <div>
                    <ReactSwitch
                        disabled={
                            e.selectedRow.code === "" ||
                            e.selectedRow.value === ""
                        }
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                        checked={e.selectedRow.status}
                        onChange={() =>
                            handleSwitchChange(!e.selectedRow.status)
                        }
                    />
                </div>
            </Tooltip>
            <div className="ml-3">
                <Tooltip
                    title={
                        e.selectedRow.isFavorite === true
                            ? ""
                            : t("Enable favorite state")
                    }
                    arrow
                >
                    <div>
                        <HeartIcon
                            onClick={handleHeartClick}
                            height={25}
                            width={25}
                            name="heart"
                            fill={e.selectedRow.isFavorite ? "red" : "black"}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                </Tooltip>
            </div>
            <div className="ml-3">
                <Tooltip title={t("To modify")} arrow>
                    <div>
                        <PencilIcon
                            onClick={handlePencilClick}
                            height={25}
                            width={25}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}
